import Vue from 'vue';

export const types = {
    SET_URL_KEY: 'SET_URL_KEY',
    SET_PRODUCTS_REVIEWS_TOTAL_COUNT: 'SET_PRODUCTS_REVIEWS_TOTAL_COUNT',
    SET_PRODUCTS_REVIEWS_AVERAGE_RATING: 'SET_PRODUCTS_REVIEWS_AVERAGE_RATING',
    SET_MODAL_ADD_PRODUCT_REVIEW_OPENED: 'SET_MODAL_ADD_PRODUCT_REVIEW_OPENED',
    SET_RECOMMENDED_PRODUCTS_BY_KEY: 'SET_RECOMMENDED_PRODUCTS_BY_KEY',
    REMOVE_RECOMMENDED_PRODUCTS_BY_KEY: 'REMOVE_RECOMMENDED_PRODUCTS_BY_KEY',
    SET_LISTING_CATEGORY_ID: 'SET_LISTING_CATEGORY_ID',
    SET_COLOR_VARIANTS: 'SET_COLOR_VARIANTS',
};

const mutations = {
    [types.SET_URL_KEY](state, urlKey) {
        state.urlKey = urlKey;
    },

    [types.SET_PRODUCTS_REVIEWS_TOTAL_COUNT](state, review) {
        state.productsReviewsTotalCount = {
            ...state.productsReviewsTotalCount,
            ...review,
        };
    },

    [types.SET_PRODUCTS_REVIEWS_AVERAGE_RATING](state, rating) {
        state.productsReviewsAverageRating = rating;
    },

    [types.SET_MODAL_ADD_PRODUCT_REVIEW_OPENED](
        state,
        isModalAddProductReviewOpened
    ) {
        state.isModalAddProductReviewOpened = isModalAddProductReviewOpened;
    },

    [types.SET_RECOMMENDED_PRODUCTS_BY_KEY](state, { key, products }) {
        Vue.set(state.recommendedProducts, key, products);
    },

    [types.REMOVE_RECOMMENDED_PRODUCTS_BY_KEY](state, key) {
        Vue.delete(state.recommendedProducts, key);
    },

    [types.SET_LISTING_CATEGORY_ID](state, listingCategory) {
        state.listingCategoryId = listingCategory;
    },

    [types.SET_COLOR_VARIANTS](state, colorVariants) {
        state.colorVariants = colorVariants;
    },
};

export default mutations;
