import Money from '@static/icons/48px/money.svg?inline';
import BankTransfer from '@static/icons/48px/bank-transfer.svg?inline';
import Blik from '@core-static/icons/methods/payment/48px/blik.svg?inline';
import CashOnDelivery from '@static/icons/48px/cash-on-delivery.svg?inline';
import PayPal from '@core-static/icons/methods/payment/48px/paypal.svg?inline';
import PayU from '@core-static/icons/methods/payment/48px/payu.svg?inline';
import VisaMastercard from '@core-static/icons/methods/payment/48px/visa_mastercard.svg?inline';
import Visa from '@core-static/icons/methods/payment/48px/visa.svg?inline';
import Mastercard from '@core-static/icons/methods/payment/48px/mastercard.svg?inline';
import PayPo from '@core-static/icons/methods/payment/40px/paypo.svg?inline';
import Klarna from '@core-static/icons/methods/payment/56px/klarna.svg?inline';
import GPay from '@core-static/icons/methods/payment/56px/gpay.svg?inline';
import Apple from '@core-static/icons/methods/payment/40px/apple.svg?inline';

import BankTransferSmall from '@static/icons/40px/bank-transfer.svg?inline';
import CashOnDeliverySmall from '@static/icons/40px/cash-on-delivery.svg?inline';

export const GATEWAY_PAYU_CODE = 'payu_gateway';
export const GATEWAY_PAYU_CARD_CODE = 'payu_gateway_card';
export const GPAY_CODE = 'google_pay';
export const GATEWAY_PAYPAL_EXPRESS_CODE = 'paypal_express';
export const GATEWAY_PAYPO_CODE = 'paypo_gateway';
export const BANK_TRANSFER_CODE = 'banktransfer';
export const BANK_TRANSFER_PERMANENT_ACCOUNT_NUMBER_CODE =
    'bank_transfer_permanent_account_number';
export const CARD_PAYMENT_CODE = 'card_payment';
export const CASH_CODE = 'cash';
export const CASH_ON_DELIVERY_IN_STORE_CODE = 'cashOnDeliveryInStore';
export const CHECKMO_CODE = 'checkmo';
export const CASH_ON_DELIVERY_CODE = 'cashondelivery';
export const BLIK_CODE = 'blik';
export const BLIK_PAYMENT = 'blik_payment';
export const VISA_CODE = 'visa';
export const MASTERCARD_CODE = 'mastercard';
export const GATEWAY_KLARNA_CODE = 'klarna_gateway';
export const GATEWAY_APPLE_PAY = 'apple_pay';
export const GIFT_CARD_CODE = 'giftcard';

export const GATEWAY_PAYMENTS_METHODS = [
    GATEWAY_PAYU_CODE,
    GPAY_CODE,
    GATEWAY_PAYU_CARD_CODE,
    GATEWAY_PAYPAL_EXPRESS_CODE,
    GATEWAY_PAYPO_CODE,
    BLIK_PAYMENT,
    GATEWAY_KLARNA_CODE,
    GATEWAY_APPLE_PAY,
    GIFT_CARD_CODE,
];

export const PAYMENT_METHOD_CODES_WITH_DEDICATED_COMPONENT = {
    GATEWAY_PAYPO_CODE,
    GATEWAY_PAYU_CARD_CODE,
    GATEWAY_KLARNA_CODE,
    BLIK_PAYMENT,
    GATEWAY_APPLE_PAY,
};

export const PAYMENT_METHOD_POSSIBLE_ERROR_CATEGORIES = {
    CUSTOMER_TYPE_INCOMPATIBILITY:
        'graphql-payment-method-not-available-for-customer',
};

export const CHECKOUT_PAYMENT_STATIC_BLOCK_IDS = {
    GATEWAY_PAYPO_CODE: 'psb_paypo_additional_checkout_info',
};

export const CHECKOUT_PAYMENT_METHOD_ICON_MAP = {
    [GATEWAY_PAYU_CODE]: PayU,
    [GATEWAY_PAYU_CARD_CODE]: VisaMastercard,
    [GATEWAY_PAYPAL_EXPRESS_CODE]: PayPal,
    [CARD_PAYMENT_CODE]: VisaMastercard,
    [GATEWAY_PAYPO_CODE]: PayPo,
    [BANK_TRANSFER_CODE]: BankTransfer,
    [BANK_TRANSFER_PERMANENT_ACCOUNT_NUMBER_CODE]: BankTransfer,
    [CASH_ON_DELIVERY_IN_STORE_CODE]: CashOnDelivery,
    [CASH_ON_DELIVERY_CODE]: CashOnDelivery,
    [BLIK_CODE]: Blik,
    [BLIK_PAYMENT]: Blik,
    [CASH_CODE]: Money,
    [CHECKMO_CODE]: Money,
    [VISA_CODE]: Visa,
    [MASTERCARD_CODE]: Mastercard,
    [GATEWAY_KLARNA_CODE]: Klarna,
    [GPAY_CODE]: GPay,
    [GATEWAY_APPLE_PAY]: Apple,
};

export const CHECKOUT_PAYMENT_METHOD_SMALL_ICON_MAP = {
    [BANK_TRANSFER_CODE]: BankTransferSmall,
    [BANK_TRANSFER_PERMANENT_ACCOUNT_NUMBER_CODE]: BankTransferSmall,
    [CASH_ON_DELIVERY_CODE]: CashOnDeliverySmall,
    [CASH_ON_DELIVERY_IN_STORE_CODE]: CashOnDeliverySmall,
};

export const GPAY_SCRIPT = {
    hid: 'gpay',
    src: 'https://pay.google.com/gp/p/js/pay.js',
    async: true,
};

export const BLIK_REJECTION_REASONS = {
    CODE_HAS_EXPIRED: 'CODE_HAS_EXPIRED',
    PAYMENT_WAS_DECLINED: 'PAYMENT_WAS_DECLINED',
    BLIK_CODE_INCORRECT: 'BLIK_CODE_INCORRECT',
    LIMIT_EXCEEDED: 'LIMIT_EXCEEDED',
    TECHNICAL_OUTAGE: 'TECHNICAL_OUTAGE',
};

export const BLIK_REJECTION_CODES_GROUPS_BY_REASONS = {
    [BLIK_REJECTION_REASONS.CODE_HAS_EXPIRED]: [
        'REG_ERROR_AUTH_CODE_EXPIRED',
        'REG_ERROR_AUTH_CODE_LIMIT_EXCEEDED',
        'AUT_ERROR_USER_TIMEOUT',
        'AUTH_CODE_EXPIRED',
    ],
    [BLIK_REJECTION_REASONS.PAYMENT_WAS_DECLINED]: [
        'REG_ERROR_AUTH_CODE_CANCELED',
        'AUT_ERROR_USER_DECLINED',
        'AUT_ERROR_NO_AUTHORIZATION',
        'AUT_ERROR_ISSUER_DECLINED',
        'AUT_ERROR_BLIK_DECLINED',
        'AUTH_CODE_CANCELED',
    ],
    [BLIK_REJECTION_REASONS.BLIK_CODE_INCORRECT]: [
        'REG_ERROR_AUTH_CODE_USED',
        'REG_ERROR_AUTH_CODE_INVALID',
        'AUTH_CODE_INVALID',
        'AUTH_CODE_USED',
    ],
    [BLIK_REJECTION_REASONS.LIMIT_EXCEEDED]: [
        'AUT_ERROR_LIMIT_EXCEEDED',
        'AUT_ERROR_INSUFFICIENT_FUNDS',
    ],
    [BLIK_REJECTION_REASONS.TECHNICAL_OUTAGE]: [
        'REG_ERROR_ISSUER_TEMPORARY',
        'AUT_ERROR_INSUFFICIENT_FUNDS',
    ],
};
