<template>
    <ButtonFAB
        :variant="buttonVariant"
        class="dev-panel"
        @default-button-click="toggleMembership()"
        @second-button-click="toggleMembershipStatus()"
    >
        <template #label> {{ labelText }} </template>
        <template v-if="isModivoClubMember" #second-label>
            {{ secondaryLabelText }}
        </template>
    </ButtonFAB>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { MODIVO_CLUB_STATUSES } from '@modivo-club-types/statuses';

import {
    BUTTON_FAB_VARIANTS,
    ButtonFAB,
} from '@eobuwie-ui/components/ButtonFAB/v1';

const {
    mapGetters: mapModivoClubGetters,
    mapActions: mapModivoClubActions,
} = createNamespacedHelpers('modivoClub');

export default {
    name: 'DevPanel',

    components: {
        ButtonFAB,
    },

    computed: {
        ...mapModivoClubGetters([
            'isModivoClubMember',
            'isModivoClubGoldMember',
        ]),

        labelText() {
            return this.isModivoClubMember
                ? 'You are club member'
                : 'Join to club';
        },

        secondaryLabelText() {
            return `status: ${
                this.isModivoClubGoldMember
                    ? MODIVO_CLUB_STATUSES.GOLD
                    : MODIVO_CLUB_STATUSES.STANDARD
            }`;
        },

        buttonVariant() {
            return this.isModivoClubMember
                ? BUTTON_FAB_VARIANTS.TWO_ACTIONS
                : BUTTON_FAB_VARIANTS.BASIC;
        },
    },

    beforeCreate() {
        this.BUTTON_FAB_TWO_BUTTONS = BUTTON_FAB_VARIANTS.TWO_ACTIONS;
    },

    methods: {
        ...mapModivoClubActions(['setMembershipInfo']),

        toggleMembership() {
            this.setMembershipInfo({
                status: this.isModivoClubMember
                    ? ''
                    : MODIVO_CLUB_STATUSES.STANDARD,
                subscriptionExpiresAt: null,
            });
        },

        toggleMembershipStatus() {
            this.setMembershipInfo({
                status: this.isModivoClubGoldMember
                    ? MODIVO_CLUB_STATUSES.STANDARD
                    : MODIVO_CLUB_STATUSES.GOLD,
                subscriptionExpiresAt: '2026-04-03T12:15:21.224Z',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dev-panel {
    @apply fixed;
    @apply left-1/2 bottom-ui-4;
    transform: translateX(-50%);
}
</style>
