import { createNamespacedHelpers } from 'vuex';

import {
    PSB_FREE_RETURN_TOOLTIP,
    PSB_FREE_DELIVERY_TOOLTIP,
} from '@configs/return-delivery-tooltips';
import {
    DAYS_FOR_RETURNS,
    STANDARD_DAYS_FOR_RETURNS,
    MODIVO_CLUB_DAYS_FOR_RETURNS,
} from '@localeConfig/keys';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import Redo from '@static/icons/24px/redo.svg?inline';
import Truck from '@static/icons/24px/truck.svg?inline';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    components: {
        Redo,
        Truck,
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConfigGetters(['freeDeliveryText']),

        isModivoClubTestEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },

        daysForReturns() {
            if (!this.isModivoClubTestEnabled) {
                return this.$t('{days} days for free refund', {
                    days: this.$getLocaleConfigByKey(DAYS_FOR_RETURNS),
                });
            }

            const standardDaysReturns = this.$t('{days} days for free refund', {
                days: this.$getLocaleConfigByKey(STANDARD_DAYS_FOR_RETURNS),
            });

            const modivoClubDaysReturns = this.$tModivoClub(
                '{days} days for MODIVOclub members',
                {
                    days: this.$getLocaleConfigByKey(
                        MODIVO_CLUB_DAYS_FOR_RETURNS
                    ),
                }
            );

            return `${standardDaysReturns},<br />${modivoClubDaysReturns}`;
        },

        infoBoxes() {
            return [
                {
                    id: PSB_FREE_RETURN_TOOLTIP,
                    icon: Redo,
                    content: this.getStaticBlockContent(
                        PSB_FREE_RETURN_TOOLTIP
                    ),
                    text: this.daysForReturns,
                },
                {
                    id: PSB_FREE_DELIVERY_TOOLTIP,
                    icon: Truck,
                    content: this.getStaticBlockContent(
                        PSB_FREE_DELIVERY_TOOLTIP
                    ),
                    text: this.freeDeliveryText,
                },
            ];
        },
    },

    methods: {
        getStaticBlockContent(blockId) {
            return this.staticBlockById(blockId)?.content || '';
        },
    },
};
