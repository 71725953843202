import { ASYNC_CHECKOUT_SERVICE_CONFIG } from '@async-services/checkout/meta';

import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';
import { GUEST_CART_ID_KEY, USER_CART_ID_KEY } from '@configs/storage';
import { PAYMENT_AND_SHIPPING_METHODS_WHITELIST } from '@configs/checkout-summary';

import { MODULE_NAME as CHECKOUT_MODULE_NAME } from '@analytics-modules/checkout/meta';
import {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    DISCOUNT_CODE_STATE,
    REMOVE_FROM_CART,
} from '@analytics-modules/checkout/types/Events';
import { DEFAULT_LOCALE } from '@analytics-modules/checkout/types/Analytics';
import {
    DISCOUNT_CODE_CORRECT,
    DISCOUNT_CODE_WRONG,
} from '@analytics-modules/checkout/types/Actions';

import {
    USER_BAD_INPUT,
    NO_SUCH_ENTITY,
    FORBIDDEN,
    PRODUCT_IS_OUT_OF_STOCK,
    PRODUCT_IS_LOW_STOCK,
} from '@types/GraphQLErrorCodes';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import {
    APPLY_PROMO_CODE,
    REMOVE_ITEM_FROM_CART,
} from '@types/CheckoutCartMetrics';

import { buildRemoveFromCartPayload } from '@assets/analytics-checkout-event';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { transformCartItemConfigurableOptions } from '@assets/cart';
import {
    startPerformanceMeasurement,
    finishPerformanceMeasurement,
    getPerformanceMeasurement,
} from '@assets/performance';

import { isGiftCardTestEnabled } from '@cherokee-assets/gift-card-ab-test';
import { BAD_REQUEST } from '@cherokee-configs/gift-card-errors';

import { types } from './mutations';

import { MODULE_NAME } from './meta';

const ADD_TO_CART_ERROR = 'Add to cart error.';

export const LOW_STOCK_ERROR = 'Low stock error.';

export const OUT_OF_STOCK_ERROR = 'Out of stock error.';

const {
    NAME: ASYNC_CHECKOUT_SERVICE_NAME,
    METHODS: { GET_PAD },
} = ASYNC_CHECKOUT_SERVICE_CONFIG;

const emitCartEvent = (
    eventName,
    {
        app,
        rootGetters,
        cartItem,
        quantity,
        esizeme,
        esizemeChosenSizeLabel,
        pageType = '',
        actionFieldList = '',
        promotedType = '',
    },
    elapsedTimeMs
) => {
    const currency = rootGetters['config/currency'];
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const averageRating = rootGetters['product/getProductReviewAverageRating'];
    const { product, sku } = cartItem;

    const productReviewTotalCount = totalCountGetter(product.sku);

    app.$analytics.moduleEmit(CHECKOUT_MODULE_NAME, eventName, {
        currency,
        elapsedTimeMs,
        product: new DataLayerProduct({
            product,
            reviews: {
                totalCountAll: productReviewTotalCount,
                averageRatings: [{ value: averageRating }],
            },
            quantity,
            variantId: sku,
            esizemeChosenSizeLabel,
            promotedType,
        }).build(),
        esizeme,
        pageType,
        actionFieldList: promotedType || actionFieldList,
    });
};

const emitAddToCartEvent = ({
    app,
    rootGetters,
    cartData,
    addToCartData,
    esizeme,
    esizemeChosenSizeLabel,
    pageType,
    actionFieldList,
    promotedType,
}) => {
    const {
        data: { quantity, sku: skuVariant },
    } = addToCartData;

    const cartItem = cartData.extCart.items.find(
        item => item.sku === skuVariant
    );

    emitCartEvent(CART_ITEM_ADD, {
        app,
        rootGetters,
        cartItem,
        quantity,
        esizeme,
        esizemeChosenSizeLabel,
        pageType,
        actionFieldList,
        promotedType,
    });
};

const emitRemoveFromCartEvent = ({
    app,
    rootGetters,
    oldCartData,
    cartItemIdString,
    qty = 0,
    elapsedTimeMs,
}) => {
    const cartItem = oldCartData.extCart.items.find(
        item => item.id === cartItemIdString
    );

    const { quantity } = cartItem;

    emitCartEvent(
        CART_ITEM_REMOVE,
        {
            app,
            rootGetters,
            cartItem,
            quantity: qty || quantity,
        },
        elapsedTimeMs
    );
};

const emitRemoveFromCartGA4Event = ({
    app,
    state,
    rootGetters,
    cartItems,
    cartItemIdString,
    quantity = 0,
    elapsedTimeMs,
}) => {
    const currency = rootGetters['config/currency'];
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const selectedPaymentMethodCode =
        rootGetters['cart/selectedPaymentMethod']?.code;
    const cartItem = cartItems.find(item => item.id === cartItemIdString);

    const {
        context: { route },
    } = app;

    const payload = buildRemoveFromCartPayload({
        currency,
        cartItems,
        cartItem,
        quantity,
        productsReviewsTotalCount: totalCountGetter(cartItem.product.sku),
        elapsedTimeMs,
        selectedPaymentType: state.selectedPaymentType,
        selectedPaymentMethodCode,
        route,
    });

    app.$analytics.moduleEmit(CHECKOUT_MODULE_NAME, REMOVE_FROM_CART, payload);
};

const actions = {
    setCartId({ rootGetters, commit }, cartId) {
        const storageCartKey = rootGetters['customer/isLoggedIn']
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;

        this.app.$storage.setItem(storageCartKey, {
            id: cartId,
        });

        commit(types.SET_CART_ID, cartId);
    },

    removeCartId({ commit }) {
        this.app.$storage.removeItem(GUEST_CART_ID_KEY);
        this.app.$storage.removeItem(USER_CART_ID_KEY);

        commit(types.SET_CART_ID, null);
    },

    setComplexDataProductItems({ commit }, complexProductDataItems) {
        commit(types.SET_COMPLEX_DATA_PRODUCT_ITEMS, complexProductDataItems);
    },

    removeComplexDataProductItems({ commit }, skusToRemove) {
        commit(types.REMOVE_COMPLEX_DATA_PRODUCT_ITEMS, skusToRemove);
    },

    async setCartData({ commit, dispatch, rootGetters, getters }, cartData) {
        const newCartData = cartData;

        if (cartData?.extCart) {
            try {
                const locale = rootGetters['config/locale'];
                const currency = rootGetters['config/currency'];
                const cartItems = cartData.extCart.items || [];

                const {
                    extCart: { ext_shipping_addresses },
                    available_payment_methods,
                    selected_payment_method,
                } = cartData;

                if (available_payment_methods) {
                    newCartData.available_payment_methods = available_payment_methods;
                }

                if (selected_payment_method) {
                    newCartData.selected_payment_method = selected_payment_method;
                }

                if (ext_shipping_addresses) {
                    newCartData.shipping_addresses = ext_shipping_addresses;
                }

                const indexesToRemove = Object.keys(
                    getters.complexProductDataItemsSkusMap
                ).filter(sku => {
                    const existsInCartItems = cartItems.find(
                        cartItem => cartItem.product.sku === sku
                    );

                    return !existsInCartItems;
                });

                if (indexesToRemove.length) {
                    dispatch('removeComplexDataProductItems', indexesToRemove);
                }

                if (cartItems.length) {
                    const allCartIndexes = cartData.extCart.items.map(
                        item => item.product.sku
                    );

                    const indexesToFetch = allCartIndexes.filter(cartSku => {
                        const productComplexDataExists =
                            getters.complexProductDataItemsSkusMap[cartSku];

                        return !productComplexDataExists;
                    });

                    const needFetchComplexData = !!indexesToFetch.length;

                    if (needFetchComplexData) {
                        const {
                            items,
                        } = await this.app.$services.product.getByIndexes({
                            indexes: indexesToFetch,
                            limit: indexesToFetch.length,
                            locale,
                            selectLocales: [DEFAULT_LOCALE],
                            currency,
                        });

                        dispatch('setComplexDataProductItems', items);
                    }

                    newCartData.extCart.items = cartData.extCart.items.map(
                        item => {
                            const searchProduct =
                                getters.complexProductDataItemsSkusMap[
                                    item.product.sku
                                ] || null;

                            const {
                                chosenSize,
                            } = transformCartItemConfigurableOptions(item);

                            item.chosenSize = chosenSize;
                            item.product = {
                                ...item.product,
                                ...searchProduct,
                            };

                            return item;
                        }
                    );
                }
            } catch (err) {
                this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                    [ERROR_ACTION_TAG_NAME]: 'product.getByIndexes',
                });
            }
        }

        commit(types.SET_CART_DATA, newCartData);
        commit(types.SET_IS_CART_DATA_VALID, true);
        commit(types.SET_IS_CART_DATA_LOADING, false);

        const isGiftCardEnabled = isGiftCardTestEnabled({
            $abTests: this.app.$abTests,
            locale: rootGetters['config/locale'],
        });

        if (isGiftCardEnabled) {
            await dispatch('getGiftCards');
        }
    },

    async clearCart({ dispatch, rootGetters }) {
        const isGiftCardEnabled = isGiftCardTestEnabled({
            $abTests: this.app.$abTests,
            locale: rootGetters['config/locale'],
        });

        const promisesToCall = [
            dispatch('removeCartId'),
            dispatch('setCartData', null),
        ];

        if (isGiftCardEnabled) {
            promisesToCall.push(dispatch('clearGiftCards'));
        }

        await Promise.all(promisesToCall);
    },

    async handleCustomerCart({ state, dispatch }) {
        const guestCartId = state.cartId;

        if (
            PAYMENT_AND_SHIPPING_METHODS_WHITELIST.includes(
                this.app.router.history.current.name
            )
        ) {
            dispatch('setFetchingPaymentAndShippingAfterLogin', true);
        }

        const isEmptyCartCreated = await dispatch('createEmptyCart');

        if (!isEmptyCartCreated) {
            return;
        }

        const userCartId = state.cartId;

        if (guestCartId !== null && userCartId !== guestCartId) {
            await dispatch('mergeGuestCartWithCustomerCart', guestCartId);
        } else {
            await dispatch('getCart', {
                cartId: state.cartId,
                withPaymentAndShippingMethods:
                    state.shouldFetchPaymentAndShippingAfterLogin,
            });
            dispatch('setFetchingPaymentAndShippingAfterLogin', false);
        }
    },

    async mergeGuestCartWithCustomerCart({ state, dispatch }, guestCartId) {
        const mergedCart = await this.app.$services.cart.mergeGuestCartWithCustomerCart(
            guestCartId,
            state.cartId
        );

        if (!mergedCart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Merging cart failed'),
                },
                { root: true }
            );

            return;
        }

        await dispatch('setCartData', mergedCart);
    },

    async handleCartAfterLogout({ dispatch }) {
        await dispatch('clearCart');
    },

    async getCart(
        { state, dispatch },
        { cartId, withPaymentAndShippingMethods = false }
    ) {
        const cartData = await this.app.$services.cart.getCart({
            cartId,
            withPaymentAndShippingMethods,
        });

        if (cartData !== null) {
            await dispatch('setCartData', cartData);

            return;
        }

        const isEmptyCartCreated = await dispatch('createEmptyCart');

        if (!isEmptyCartCreated) {
            return;
        }

        const newCartData = await this.app.$services.cart.getCart({
            cartId: state.cartId,
            withPaymentAndShippingMethods,
        });

        if (newCartData !== null) {
            await dispatch('setCartData', newCartData);

            return;
        }

        dispatch(
            'messages/addErrorMessage',
            {
                text: this.app.i18n.t('Fetching cart failed'),
            },
            { root: true }
        );
    },

    async getCartByPostcode({ state, commit, dispatch }, postcode) {
        commit(types.SET_IS_FETCHING_CART_WITH_POSTCODE, true);

        const { cartId } = state;

        const resultCart = await this.app.$services.cart.getCartByPostcode({
            cartId,
            postcode,
        });

        if (!resultCart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Fetching cart failed'),
                },
                { root: true }
            );
        } else {
            await dispatch('setCartData', resultCart);
        }

        commit(types.SET_IS_FETCHING_CART_WITH_POSTCODE, false);
    },

    syncCartId({ rootGetters, commit, state }) {
        const { cartId: stateCartId } = state;
        const storageCartKey = rootGetters['customer/isLoggedIn']
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;
        const { id: storageCartId = null } =
            this.$storage.getItem(storageCartKey) || {};

        try {
            if (
                storageCartId &&
                (!stateCartId || stateCartId !== storageCartId)
            ) {
                commit(types.SET_CART_ID, storageCartId);
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.syncCartId',
                },
                {
                    storageCartId,
                    stateCartId,
                }
            );
        }
    },

    async createEmptyCart({ commit, dispatch }) {
        const cartId = await this.app.$services.cart.createEmptyCart();

        if (!cartId) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Creating empty cart error occurred'),
                },
                { root: true }
            );

            return false;
        }

        dispatch('setCartId', cartId);

        commit(types.SET_IS_CART_DATA_LOADING, false);

        return true;
    },

    async addProductToCart(
        { rootGetters, dispatch, state },
        {
            productName,
            addToCartData,
            esizeme,
            pageType,
            actionFieldList,
            promotedType,
        }
    ) {
        const storageCartKey = rootGetters['customer/isLoggedIn']
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;
        const cart = this.$storage.getItem(storageCartKey);

        if (!cart) {
            const isEmptyCartCreated = await dispatch('createEmptyCart');

            if (!isEmptyCartCreated) {
                return;
            }
        }

        const {
            data: { sku },
            parent_sku: parentSku,
            esizeme_info: esizemeInfo,
        } = addToCartData;

        const { recommended_size: esizemeChosenSizeLabel } = esizemeInfo || {};

        try {
            const result = await dispatch(
                'addConfigurableProductToCart',
                addToCartData
            );

            if (result) {
                emitAddToCartEvent({
                    app: this.app,
                    rootGetters,
                    cartData: state.cartData,
                    addToCartData,
                    esizeme,
                    pageType,
                    actionFieldList,
                    esizemeChosenSizeLabel,
                    promotedType,
                });
            }

            return sku;
        } catch (err) {
            const { message: errorMessage } = err;

            if (
                ![
                    ADD_TO_CART_ERROR,
                    OUT_OF_STOCK_ERROR,
                    LOW_STOCK_ERROR,
                ].includes(errorMessage)
            ) {
                await this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addToCart',
                    },
                    {
                        addToCartData,
                        sku,
                    }
                );
            }

            if (![OUT_OF_STOCK_ERROR, LOW_STOCK_ERROR].includes(errorMessage)) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Adding {productName} to cart failed',
                            {
                                productName,
                            }
                        ),
                    },
                    { root: true }
                );
            } else {
                dispatch(
                    'modals/openAddProductToCartErrorModal',
                    {
                        error: errorMessage,
                        sku: parentSku,
                    },
                    {
                        root: true,
                    }
                );
            }
        }

        return false;
    },

    async addConfigurableProductToCart({ state, dispatch }, addToCartData) {
        dispatch('syncCartId');

        const {
            cart,
            errorCode,
        } = await this.app.$services.cart.addConfigurableProductToCart({
            cartId: state.cartId,
            cartItems: [addToCartData],
        });

        if (!cart) {
            if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return false;
            }

            if (errorCode === PRODUCT_IS_OUT_OF_STOCK) {
                throw new Error(OUT_OF_STOCK_ERROR);
            } else if (errorCode === PRODUCT_IS_LOW_STOCK) {
                throw new Error(LOW_STOCK_ERROR);
            }
        }

        if (cart === null) {
            throw new Error(ADD_TO_CART_ERROR);
        }

        await dispatch('setCartData', cart);

        return true;
    },

    async removeItemFromCart(
        { rootGetters, state, dispatch },
        cartItemIdString
    ) {
        startPerformanceMeasurement(REMOVE_ITEM_FROM_CART);
        const { app } = this;
        const oldCartData = { ...state.cartData };
        const cartItemId = Number.parseInt(cartItemIdString, 10);

        const {
            cart,
            errorCode,
        } = await this.app.$services.cart.removeItemFromCart({
            cartId: state.cartId,
            cartItemId,
        });

        if (!cart) {
            if (errorCode === NO_SUCH_ENTITY) {
                dispatch('getCart', { cartId: state.cartId });

                dispatch(
                    'messages/addSuccessMessage',
                    {
                        text: this.app.i18n.t(
                            'Product removed successfully from cart'
                        ),
                    },
                    { root: true }
                );

                return true;
            }

            if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return false;
            }

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Removing item from cart error occurred'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('Product removed successfully from cart'),
            },
            { root: true }
        );

        finishPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

        const elapsedTimeMs = getPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

        emitRemoveFromCartEvent({
            app,
            rootGetters,
            oldCartData,
            cartItemIdString,
            elapsedTimeMs,
        });

        emitRemoveFromCartGA4Event({
            app,
            state,
            rootGetters,
            cartItems: oldCartData.extCart.items,
            cartItemIdString,
            elapsedTimeMs,
        });

        return true;
    },

    async updateCartItem({ rootGetters, state, dispatch }, { id, qty }) {
        const { app } = this;
        const oldCartData = { ...state.cartData };
        const cartItemId = Number.parseInt(id, 10);
        const cartItem = state.cartData.extCart?.items?.find(
            item => item.id === id
        );

        if (qty < cartItem.quantity) {
            startPerformanceMeasurement(REMOVE_ITEM_FROM_CART);
        }

        const {
            cart,
            errorCode,
        } = await this.app.$services.cart.updateCartItem({
            cartId: state.cartId,
            cartItems: [{ cart_item_id: cartItemId, quantity: qty }],
        });

        if (!cart) {
            if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return;
            }

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Updating product in cart error occured'
                    ),
                },
                { root: true }
            );

            return;
        }

        await dispatch('setCartData', cart);

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('Product successfully updated'),
            },
            { root: true }
        );

        const {
            sku: skuVariant,
            product: { sku },
            quantity: oldQuantity,
            chosenSize,
        } = cartItem;

        const quantityDiff = Math.abs(qty - oldQuantity);

        if (qty > oldQuantity) {
            const addToCartData = {
                parent_sku: sku,
                data: {
                    sku: skuVariant,
                    quantity: quantityDiff,
                },
            };

            emitAddToCartEvent({
                app,
                rootGetters,
                cartData: cart,
                addToCartData,
                chosenSizeLabel: chosenSize,
            });
        } else if (qty < oldQuantity) {
            finishPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

            const elapsedTimeMs = getPerformanceMeasurement(
                REMOVE_ITEM_FROM_CART
            );

            emitRemoveFromCartEvent({
                app,
                rootGetters,
                oldCartData,
                cartItemIdString: id,
                qty: quantityDiff,
            });

            emitRemoveFromCartGA4Event({
                app,
                state,
                rootGetters,
                cartItems: oldCartData.extCart.items,
                cartItemIdString: id,
                elapsedTimeMs,
                quantity: quantityDiff,
            });
        }
    },

    async changeCartItem(
        { rootGetters, state, dispatch },
        { id, parentSku, sku, qty, chosenSize: newChosenSize }
    ) {
        startPerformanceMeasurement(REMOVE_ITEM_FROM_CART);
        const { app } = this;
        let latestCartData = { ...state.cartData };
        const cartItemId = Number.parseInt(id, 10);

        const { cart } = await this.app.$services.cart.removeItemFromCart({
            cartId: state.cartId,
            cartItemId,
        });

        finishPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

        if (cart) {
            const elapsedTimeMs = getPerformanceMeasurement(
                REMOVE_ITEM_FROM_CART
            );

            emitRemoveFromCartEvent({
                app,
                rootGetters,
                oldCartData: latestCartData,
                cartItemIdString: id,
            });

            emitRemoveFromCartGA4Event({
                app,
                state,
                rootGetters,
                cartItems: latestCartData.extCart.items,
                cartItemIdString: id,
                elapsedTimeMs,
            });

            latestCartData = cart;
        }

        const addToCartData = {
            parent_sku: parentSku,
            data: {
                sku,
                quantity: qty,
            },
        };

        try {
            await dispatch('addConfigurableProductToCart', addToCartData);

            dispatch(
                'messages/addSuccessMessage',
                {
                    text: this.app.i18n.t('Product successfully updated'),
                },
                { root: true }
            );

            emitAddToCartEvent({
                app,
                rootGetters,
                cartData: state.cartData,
                addToCartData,
                chosenSizeLabel: newChosenSize,
            });
        } catch (err) {
            if (err.message !== ADD_TO_CART_ERROR) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addConfigurableProductToCart',
                    },
                    {
                        addToCartData,
                    }
                );
            }

            await dispatch('setCartData', latestCartData);

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Updating product in cart error occured'
                    ),
                },
                { root: true }
            );
        }
    },

    async resetShippingMethodOnCart({ state, dispatch }) {
        const cart = await this.$services.cart.resetShippingMethodOnCart(
            state.cartId
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Resetting shipping method on cart error occurred'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        return true;
    },

    async setShippingAddressOnCart({ state, dispatch }, { address }) {
        const cart = await this.$services.cart.setShippingAddressOnCart(
            state.cartId,
            {
                address,
            }
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Setting shipping address on cart error occurred'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        return true;
    },

    async setShippingMethodOnCart({ state, dispatch }, method) {
        const cart = await this.app.$services.cart.setShippingMethodOnCart(
            state.cartId,
            method
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Setting shipping method failed. Please try again in a moment'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        return true;
    },

    setFetchingPaymentAndShippingAfterLogin({ commit }, shouldFetch) {
        commit(
            types.SET_FETCHING_PAYMENT_AND_SHIPPING_AFTER_LOGIN,
            shouldFetch
        );
    },

    async setInpostPointOnCart({ state, dispatch }, point) {
        let pointId = null;

        if (point && point.name) {
            pointId = point.name;
        }

        const result = await this.app.$services.cart.setInpostPointOnCart(
            state.cartId,
            pointId
        );

        if (pointId && !result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Setting inpost point error occured'),
                },
                { root: true }
            );
        }

        return result;
    },

    async setStorePickupPointOnCart({ state, dispatch }, point) {
        let pointId = null;

        if (point && point.id) {
            pointId = point.id;
        }

        const result = await this.app.$services.cart.setStorePickupPointOnCart(
            state.cartId,
            pointId
        );

        if (pointId && !result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Setting store pickup point error occured'
                    ),
                },
                { root: true }
            );
        }

        return result;
    },

    async setShippingPointOnCart({ state, dispatch, commit }, point) {
        const pointId = point?.id ?? null;

        const result = await this.app.$services.cart.setShippingPointOnCart(
            state.cartId,
            pointId
        );

        if (pointId && !result) {
            commit(types.SET_IS_CART_DATA_VALID, false);
            commit(types.REMOVE_SELECTED_SHIPPING_POINT, null);

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Setting pickup point error occured'),
                },
                { root: true }
            );
        }

        return result;
    },

    async setDpdParcelShopPointOnCart({ state, dispatch }, point) {
        const pointId = point?.id ?? null;

        const result = await this.app.$services.cart.setDpdParcelShopPointOnCart(
            state.cartId,
            pointId
        );

        if (pointId && !result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Setting pickup point error occured'),
                },
                { root: true }
            );
        }

        return result;
    },

    async setPaymentMethodOnCart({ state, dispatch }, method) {
        const cart = await this.app.$services.cart.setPaymentMethodOnCart(
            state.cartId,
            method
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Setting payment method failed. Please try again in a moment'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        return true;
    },

    async setBillingAddressOnCart({ state, dispatch }, billingAddressInput) {
        const cart = await this.app.$services.cart.setBillingAddressOnCart(
            state.cartId,
            billingAddressInput
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Setting billing address on cart error occurred'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);

        return true;
    },

    async setGuestEmailOnCart({ state, dispatch }, email) {
        const cart = await this.app.$services.cart.setGuestEmailOnCart(
            state.cartId,
            email
        );

        if (cart) {
            await dispatch('setCartData', cart);

            return;
        }

        dispatch(
            'messages/addErrorMessage',
            {
                text: this.app.i18n.t(
                    'Setting email address on cart error occurred'
                ),
            },
            { root: true }
        );
    },

    async applyCouponToCart({ state, dispatch }, couponCode) {
        startPerformanceMeasurement(APPLY_PROMO_CODE);

        let result;

        const {
            cart,
            error,
            couponApplicationInfo,
        } = await this.app.$services.cart.applyCouponToCart(
            state.cartId,
            couponCode
        );

        if (error === USER_BAD_INPUT) {
            result = {
                success: false,
                error: this.app.i18n.t('Wrong discount code'),
                couponApplicationInfo: couponApplicationInfo || {},
            };
        } else if (cart) {
            await dispatch('setCartData', cart);
            await dispatch('getPad', state.cartId);

            result = {
                success: true,
                error: '',
                couponApplicationInfo,
            };
        }

        result = result || {
            success: false,
            error: this.app.i18n.t(
                'Setting coupon code on cart error occurred'
            ),
            couponApplicationInfo,
        };

        finishPerformanceMeasurement(APPLY_PROMO_CODE);

        const reason =
            couponApplicationInfo?.coupon_info?.rule_conditions || '';
        const code = reason ? `${couponCode} | ${reason}` : couponCode;

        this.app.$analytics.moduleEmit(
            CHECKOUT_MODULE_NAME,
            DISCOUNT_CODE_STATE,
            {
                action: result.success
                    ? DISCOUNT_CODE_CORRECT
                    : DISCOUNT_CODE_WRONG,
                code,
                elapsedTimeMs: getPerformanceMeasurement(APPLY_PROMO_CODE),
            }
        );

        return result;
    },

    async removeCouponFromCart({ state, dispatch }) {
        const cart = await this.app.$services.cart.removeCouponFromCart(
            state.cartId
        );

        if (!cart) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Removing coupon code from cart error occurred'
                    ),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('setCartData', cart);
        await dispatch('getPad', state.cartId);

        return true;
    },

    async setPadData({ commit }, padData) {
        commit(types.SET_PAD_DATA, padData);
    },

    async getPad({ dispatch }, cartId) {
        try {
            const pad = await this.$asyncServices.use(
                ASYNC_CHECKOUT_SERVICE_NAME,
                GET_PAD,
                {
                    cartId,
                }
            );

            dispatch('setPadData', pad);
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.getPad',
                },
                {
                    cartId,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'The download of payment and delivery methods failed'
                    ),
                },
                { root: true }
            );
        }
    },

    async assignCashbackToCart(
        { state, getters, rootGetters, rootState, dispatch },
        amountToCover
    ) {
        const { cartId } = state;
        const { cardNumber, balance } =
            rootState.modivoClub.cashbackCardData || {};

        if (!cardNumber || !balance) {
            return;
        }

        try {
            const {
                data,
                error,
                status,
            } = await this.app.$services.prePaid.assignPrePaidToCart({
                cartId,
                cardNumber,
                amountToCover,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (!error) {
                const { prePaidCards, extraPaymentMethodCode } = data;

                dispatch('setGiftCards', prePaidCards);
                dispatch(
                    'setAdditionalPaymentMethodCode',
                    extraPaymentMethodCode
                );

                return { data };
            }

            if (status === BAD_REQUEST) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.tModivoClub(
                            'Adding cashback to cart failed'
                        ),
                    },
                    { root: true }
                );
            }
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.assignWalletToCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    async getGiftCards({ dispatch, getters, rootGetters }) {
        await dispatch('getAssignedGiftCards');

        const isModivoClubGoldMember =
            rootGetters['modivoClub/isModivoClubGoldMember'];

        if (isModivoClubGoldMember) {
            const isCashbackAvailable =
                rootGetters['modivoClub/isCashbackAvailable'];

            const isCashbackAssignedToCart = !!getters.cashbackCard;

            if (isCashbackAvailable && !isCashbackAssignedToCart) {
                await dispatch(
                    'assignCashbackToCart',
                    rootGetters['modivoClub/cashbackTotalFundsAmount']
                );
            }
        }
    },

    setPaymentTypeOnCart({ commit }, paymentType) {
        commit(types.SET_PAYMENT_TYPE, paymentType);
    },

    addToCartPending({ commit }, isAddToCartPending) {
        commit(types.SET_IS_ADD_TO_CART_PENDING, isAddToCartPending);
    },

    setIsAssociatedProductCardClosed({ commit }, isClosed) {
        commit(types.SET_IS_ASSOCIATED_PRODUCT_CARD_CLOSED, isClosed);
    },

    async changeGiftCardsEnabledState({ dispatch, state }, cards) {
        const { cartId } = state;

        try {
            const cardsStates = cards.map(({ cardNumber, enabled }) => ({
                cardNumber,
                enabled,
            }));

            const {
                error,
                data,
            } = await this.$services.prePaid.changeCardVisibility({
                cartId,
                prePaidCards: cardsStates,
            });

            if (error) {
                throw new Error(error, { cause: { error } });
            }

            const { prePaidCards, extraPaymentMethodCode } = data;

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodCode', extraPaymentMethodCode);

            return true;
        } catch {
            return false;
        }
    },

    async deactivateAllGiftCards({ state, getters, commit, dispatch }) {
        if (!getters.isAnyGiftCardActive) {
            return true;
        }

        const currentGiftCards = state.giftCards;

        const newGiftCardsStates = state.giftCards.map(({ cardNumber }) => ({
            cardNumber,
            enabled: false,
        }));

        const result = await dispatch(
            'changeGiftCardsEnabledState',
            newGiftCardsStates
        );

        if (!result) {
            return false;
        }

        commit(types.SET_STORED_GIFT_CARDS, currentGiftCards);

        dispatch(
            'messages/addInfoMessage',
            {
                text: this.app.i18n.t('The gift card has been removed'),
            },
            { root: true }
        );

        return true;
    },

    async activateStoredGiftCards({ state, getters, commit, dispatch }) {
        if (!getters.areAnyGiftCardsStored) {
            return true;
        }

        const newGiftCardsStates = state.giftCards.map(giftCard => {
            const storedGiftCard = state.storedGiftCards.find(
                ({ cardNumber }) => cardNumber === giftCard.cardNumber
            );

            return {
                ...giftCard,
                enabled: storedGiftCard?.enabled || giftCard.enabled,
            };
        });

        const result = await dispatch(
            'changeGiftCardsEnabledState',
            newGiftCardsStates
        );

        if (!result) {
            return false;
        }

        commit(types.SET_STORED_GIFT_CARDS, []);

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('Gift card added to order'),
            },
            { root: true }
        );

        return true;
    },

    async getAssignedGiftCards({ dispatch, state, getters }) {
        const { cartId } = state;

        try {
            const {
                data,
                error,
                status,
            } = await this.app.$services.prePaid.getAssignedPrePaidCards({
                cartId,
            });

            if (status === NOT_FOUND_HTTP_CODE) {
                return;
            }

            if (error) {
                throw new Error(error, { cause: error });
            }

            const { orderAmount, prePaidCards, extraPaymentMethodCode } = data;

            if (getters.grandTotalNumber !== orderAmount.amount) {
                dispatch('updateOrderAmount');

                return;
            }

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodCode', extraPaymentMethodCode);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.getAssignedGiftCards',
                },
                { cartId }
            );
        }
    },

    async updateOrderAmount({ state, getters, rootGetters, dispatch }) {
        const { cartId } = state;

        try {
            const {
                data,
                error,
            } = await this.app.$services.prePaid.updateOrderAmount({
                cartId,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (error) {
                throw new Error(error, { cause: error });
            }

            const { prePaidCards, extraPaymentMethodCode } = data;

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodCode', extraPaymentMethodCode);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.updateOrderAmount',
                },
                { cartId }
            );
        }
    },

    async addGiftCard(
        { state, getters, rootGetters, dispatch },
        { cardNumber, cardPin }
    ) {
        const { cartId } = state;

        try {
            const {
                data,
                error,
            } = await this.app.$services.prePaid.assignPrePaidToCart({
                cartId,
                cardNumber,
                cardPin,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (!error) {
                const { prePaidCards, extraPaymentMethodCode } = data;

                dispatch('setGiftCards', prePaidCards);
                dispatch(
                    'setAdditionalPaymentMethodCode',
                    extraPaymentMethodCode
                );

                return { data };
            }

            return { error };
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.addGiftCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    async removeGiftCard({ state, dispatch }, { cardNumber }) {
        const { cartId } = state;

        try {
            const {
                error,
            } = await this.app.$services.prePaid.detachPrePaidFromCart({
                cartId,
                cardNumber,
            });

            if (error) {
                throw new Error(error, { cause: error });
            }

            const giftCards = state.giftCards.filter(
                card => card.cardNumber !== cardNumber
            );

            dispatch('setGiftCards', giftCards);

            if (giftCards.length === 0) {
                dispatch('additionalPaymentMethodCode', '');
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.removeGiftCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    async setAdditionalPaymentMethod({ dispatch, state }, { code }) {
        const { cartId } = state;

        const {
            data,
            error,
        } = await this.$services.prePaid.extraPaymentMethodSet({
            cartId,
            paymentMethodCode: code,
        });

        if (error) {
            // @TODO remove in FRONT-375
            console.log(error);

            return false;
        }

        const { prePaidCards, extraPaymentMethodCode } = data;

        dispatch('setGiftCards', prePaidCards);
        dispatch('setAdditionalPaymentMethodCode', extraPaymentMethodCode);

        return true;
    },

    async resetAdditionalPaymentMethod({ state, dispatch }) {
        const { cartId } = state;
        const { error } = this.app.$services.prePaid.extraPaymentMethodUnSet({
            cartId,
        });

        if (!error) {
            dispatch('setAdditionalPaymentMethodCode', '');
        }

        return !error;
    },

    setAdditionalPaymentMethodCode({ commit }, code) {
        commit(types.SET_ADDITIONAL_PAYMENT_METHOD_CODE, code);
    },

    setGiftCards({ commit }, giftCards) {
        commit(types.SET_GIFT_CARDS, giftCards);
    },

    setStoredGiftCards({ commit }, storedGiftCards) {
        commit(types.SET_STORED_GIFT_CARDS, storedGiftCards);
    },

    clearGiftCards({ dispatch }) {
        dispatch('setGiftCards', []);
        dispatch('setStoredGiftCards', []);
        dispatch('setAdditionalPaymentMethodCode', '');
    },

    showLoader({ commit }, text = '') {
        commit(types.ADD_LOADER, text);
    },

    hideLoader({ commit }) {
        commit(types.REMOVE_LOADER);
    },

    clearLoader({ commit }) {
        commit(types.CLEAR_LOADER);
    },
};

export default actions;
