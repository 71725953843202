import { PREVENT_SCROLL_CLASSES } from '@configs/class-names';
import { types } from './mutations';

export default {
    addHTMLClasses({ commit }, classNames = []) {
        commit(types.ADD_HTML_CLASSES, classNames);

        const html = document.documentElement;

        if (
            PREVENT_SCROLL_CLASSES.some(
                preventScrollClassName =>
                    classNames.includes(preventScrollClassName) &&
                    !html.classList.contains(preventScrollClassName)
            )
        ) {
            html.style.top = `${-window.scrollY}px`;
        }
    },

    removeHTMLClasses({ commit, dispatch }, classNames = []) {
        commit(types.REMOVE_HTML_CLASSES, classNames);

        const html = document.documentElement;

        if (
            PREVENT_SCROLL_CLASSES.some(
                preventScrollClassName =>
                    classNames.includes(preventScrollClassName) &&
                    html.classList.contains(preventScrollClassName)
            )
        ) {
            dispatch('blockScrollToTop', classNames);
        }
    },

    blockScrollToTop(_, classNames = []) {
        const html = document.documentElement;
        const scrollY = html.style.top;

        html.classList.remove(...classNames);
        html.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    },
};
