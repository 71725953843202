import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _151feee0 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _13dfae74 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _75494df0 = () => interopDefault(import('../pages/checkout-account.vue' /* webpackChunkName: "pages/checkout-account" */))
const _3dbfc5ed = () => interopDefault(import('../pages/checkout-cart.vue' /* webpackChunkName: "pages/checkout/cart" */))
const _41822dd1 = () => interopDefault(import('../pages/login-register.vue' /* webpackChunkName: "pages/login-register" */))
const _49091615 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _3a95aa03 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _708bb083 = () => interopDefault(import('../pages/sms-unsubscribe.vue' /* webpackChunkName: "pages/sms-unsubscribe" */))
const _f8ee9718 = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _0314f738 = () => interopDefault(import('../pages/homepage.vue' /* webpackChunkName: "pages/homepage" */))
const _e6bbcce2 = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _e0a678b4 = () => interopDefault(import('../pages/add-return.vue' /* webpackChunkName: "pages/add-return" */))
const _da2c6b92 = () => interopDefault(import('../pages/after-add-return.vue' /* webpackChunkName: "pages/after-add-return" */))
const _9705d4ba = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _160f7296 = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _5829a1b0 = () => interopDefault(import('../pages/customer.vue' /* webpackChunkName: "pages/customer" */))
const _d75c90aa = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _3c7cfcdb = () => interopDefault(import('../pages/customer/details.vue' /* webpackChunkName: "pages/customer/details" */))
const _20c91e97 = () => interopDefault(import('../pages/customer/esizeme.vue' /* webpackChunkName: "pages/customer/esizeme" */))
const _0c3299fb = () => interopDefault(import('../pages/customer/addresses.vue' /* webpackChunkName: "pages/customer/addresses" */))
const _6dafdf5f = () => interopDefault(import('../pages/customer/orders/index.vue' /* webpackChunkName: "pages/customer/orders" */))
const _01716254 = () => interopDefault(import('../pages/customer/orders/orderId.vue' /* webpackChunkName: "pages/customer/orders/orderId" */))
const _07bd6a34 = () => interopDefault(import('../pages/customer/reviews.vue' /* webpackChunkName: "pages/customer/reviews" */))
const _495d9dc0 = () => interopDefault(import('../pages/customer/agreements.vue' /* webpackChunkName: "pages/customer/agreements" */))
const _5e180b5c = () => interopDefault(import('../pages/customer/returns.vue' /* webpackChunkName: "pages/customer/returns" */))
const _1bb0eaa7 = () => interopDefault(import('../pages/customer/return.vue' /* webpackChunkName: "pages/customer/return" */))
const _6bd6afbb = () => interopDefault(import('../pages/customer/invite-friend.vue' /* webpackChunkName: "pages/customer/invite-friend" */))
const _17156994 = () => interopDefault(import('../pages/customer/loyalty-club.vue' /* webpackChunkName: "pages/customer/loyalty-club" */))
const _2cb9e95c = () => interopDefault(import('../pages/customer/modivo-club.vue' /* webpackChunkName: "pages/customer/modivo-club" */))
const _8899d85a = () => interopDefault(import('../pages/guest-invite-friend.vue' /* webpackChunkName: "pages/guest-invite-friend" */))
const _92dfbb08 = () => interopDefault(import('../pages/guest-loyalty-club.vue' /* webpackChunkName: "pages/guest-loyalty-club" */))
const _7d185df8 = () => interopDefault(import('../pages/invite-friend-register.vue' /* webpackChunkName: "pages/invite-friend-register" */))
const _0a6d9c97 = () => interopDefault(import('../pages/invite-friend-inactive-link.vue' /* webpackChunkName: "pages/invite-friend-inactive-link" */))
const _c2eb7314 = () => interopDefault(import('../pages/checkout-repayment.vue' /* webpackChunkName: "pages/checkout-repayment" */))
const _79e8141b = () => interopDefault(import('../pages/order-status.vue' /* webpackChunkName: "pages/order-status" */))
const _1de8446f = () => interopDefault(import('../pages/cms.vue' /* webpackChunkName: "pages/cms" */))
const _b73d2674 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _97c50cb0 = () => interopDefault(import('../pages/package-tracking.vue' /* webpackChunkName: "pages/package-tracking" */))
const _478d3e90 = () => interopDefault(import('../modules/page-builder/page-builder.vue' /* webpackChunkName: "pages/page-builder" */))
const _e4c5db4c = () => interopDefault(import('../pages/not-supported.vue' /* webpackChunkName: "pages/NotSupported" */))
const _b6e325b4 = () => interopDefault(import('../pages/web-links.vue' /* webpackChunkName: "pages/web-links" */))
const _808daca0 = () => interopDefault(import('../pages/mobile-token-expired.vue' /* webpackChunkName: "pages/mobile-token-expired" */))
const _1cabe340 = () => interopDefault(import('../pages/ModivoClub.vue' /* webpackChunkName: "pages/modivo-club" */))
const _9ca6caae = () => interopDefault(import('../pages/modivo-club-gift-card.vue' /* webpackChunkName: "pages/modivoclubgiftcard" */))
const _733b752e = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/NotFound" */))
const _7570a250 = () => interopDefault(import('../modules/rma/src/pages/Rma.vue' /* webpackChunkName: "pages/rma" */))
const _52a3caa2 = () => interopDefault(import('../modules/rma/src/pages/RmaProcess.vue' /* webpackChunkName: "pages/rma/process" */))
const _33c659cc = () => interopDefault(import('../modules/rma/src/pages/process/RmaCreate.vue' /* webpackChunkName: "pages/rma/create" */))
const _7310db83 = () => interopDefault(import('../modules/rma/src/pages/RmaReturns.vue' /* webpackChunkName: "pages/rma/returns" */))
const _74092342 = () => interopDefault(import('../modules/rma/src/pages/returns/RmaList.vue' /* webpackChunkName: "pages/rma/list" */))
const _8d26ed64 = () => interopDefault(import('../modules/rma/src/pages/returns/RmaDetails.vue' /* webpackChunkName: "pages/rma/details" */))
const _4d906924 = () => interopDefault(import('../modules/search/src/pages/Search.vue' /* webpackChunkName: "pages/search" */))
const _347d7cc3 = () => interopDefault(import('../modules/search/src/pages/Catalog.vue' /* webpackChunkName: "pages/category" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _151feee0,
    name: "checkout"
  }, {
    path: "/checkout/success/:urlKey?",
    component: _13dfae74,
    name: "checkout-success"
  }, {
    path: "/checkout/account",
    component: _75494df0,
    name: "checkout-account"
  }, {
    path: "/checkout/cart",
    component: _3dbfc5ed,
    name: "checkout-cart"
  }, {
    path: "/login",
    component: _41822dd1,
    name: "login"
  }, {
    path: "/register",
    component: _41822dd1,
    name: "register"
  }, {
    path: "/p/:urlKey",
    component: _49091615,
    name: "product"
  }, {
    path: "/newsletter/:urlKey",
    component: _3a95aa03,
    name: "newsletter"
  }, {
    path: "/us",
    component: _708bb083,
    name: "sms-unsubscribe"
  }, {
    path: "/order/:urlKey?",
    component: _f8ee9718,
    name: "order"
  }, {
    path: "/",
    component: _0314f738,
    name: "homepage"
  }, {
    path: "/wishlist",
    component: _e6bbcce2,
    name: "wishlist"
  }, {
    path: "/add-return",
    component: _e0a678b4,
    name: "add-return"
  }, {
    path: "/after-add-return",
    component: _da2c6b92,
    name: "after-add-return"
  }, {
    path: "/new-password",
    component: _9705d4ba,
    name: "new-password"
  }, {
    path: "/reset-password",
    component: _41822dd1,
    name: "reset-password"
  }, {
    path: "/reset-password/new/",
    component: _9705d4ba,
    name: "reset-password-new"
  }, {
    path: "/brands",
    component: _160f7296,
    name: "brands"
  }, {
    path: "/customer",
    component: _5829a1b0,
    children: [{
      path: "/",
      component: _d75c90aa,
      name: "customer"
    }, {
      path: "details",
      component: _3c7cfcdb,
      name: "customer/details"
    }, {
      path: "esizeme",
      component: _20c91e97,
      name: "customer/esizeme"
    }, {
      path: "addresses",
      component: _0c3299fb,
      name: "customer/addresses"
    }, {
      path: "orders",
      component: _6dafdf5f,
      name: "customer/orders"
    }, {
      path: "orders/:orderId",
      component: _01716254,
      name: "customer/orders/order-view"
    }, {
      path: "reviews",
      component: _07bd6a34,
      name: "customer/reviews"
    }, {
      path: "agreements",
      component: _495d9dc0,
      name: "customer/agreements"
    }, {
      path: "returns",
      component: _5e180b5c,
      name: "customer/returns"
    }, {
      path: "return",
      component: _1bb0eaa7,
      name: "customer/add-return"
    }, {
      path: "invite-friend",
      component: _6bd6afbb,
      name: "customer/invite-friend"
    }, {
      path: "loyalty-club",
      component: _17156994,
      name: "customer/loyalty-club"
    }, {
      path: "modivo-club",
      component: _2cb9e95c,
      name: "customer/modivo-club"
    }]
  }, {
    path: "/invite-friend",
    component: _8899d85a,
    name: "guest-invite-friend"
  }, {
    path: "/loyalty-club",
    component: _92dfbb08,
    name: "guest-loyalty-club"
  }, {
    path: "/ref/:inviteToken",
    component: _7d185df8,
    name: "invite-friend-ref-link-name"
  }, {
    path: "/inactive-ref",
    component: _0a6d9c97,
    name: "invite-friend-inactive-ref-link-name"
  }, {
    path: "/checkout/repayment/:orderHash",
    component: _c2eb7314,
    name: "checkout-repayment"
  }, {
    path: "/order-status/:orderHash",
    component: _79e8141b,
    name: "order-status"
  }, {
    path: "/b/:identifier",
    component: _1de8446f,
    name: "cms"
  }, {
    path: "/:contact(kontakti|kontakt|epikoinonia|contact|contacto|kapcsolat|contatti|kontaktai)",
    component: _b73d2674,
    name: "contact"
  }, {
    path: "/package-tracking",
    component: _97c50cb0,
    name: "package-tracking"
  }, {
    path: "/pb",
    component: _478d3e90,
    name: "pb"
  }, {
    path: "/not-supported",
    component: _e4c5db4c,
    name: "not-supported"
  }, {
    path: "/web-links",
    component: _b6e325b4,
    name: "web-links"
  }, {
    path: "/token-expired",
    component: _808daca0,
    name: "token-expired"
  }, {
    path: "/modivo-club",
    component: _1cabe340,
    name: "modivo-club"
  }, {
    path: "/:modivoClubGiftCard(modivo-club-gift-card|karta-podarunkowa)",
    component: _9ca6caae,
    name: "modivoClubGiftCard"
  }, {
    path: "*",
    component: _733b752e,
    name: "not-found"
  }, {
    path: "/rma",
    component: _7570a250,
    children: [{
      path: "",
      component: _52a3caa2,
      children: [{
        path: "create/:orderNumber?",
        component: _33c659cc,
        name: "rma/create"
      }]
    }, {
      path: "returns",
      component: _7310db83,
      children: [{
        path: "",
        component: _74092342,
        name: "rma/list"
      }, {
        path: ":returnUuid",
        component: _8d26ed64,
        name: "rma/details"
      }]
    }]
  }, {
    path: "/s/:url(.*)",
    component: _4d906924,
    name: "search"
  }, {
    path: "/c/:url(.*)",
    component: _347d7cc3,
    name: "category"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
