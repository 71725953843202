const state = () => ({
    isOpenAvailabilityNotificationModal: false,
    isOpenAddToCartErrorModal: false,
    cartItemData: null,
    shouldOpenSniperLinkModal: false,
    isSizeModalOpen: false,
    shouldRedirectToCheckoutOnJoinModivoClubModalClose: false,
});

export default state;
