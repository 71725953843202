export const STORAGE_PREFIX = '__EOBUWIE__';
export const COOKIE_PREFIX = '__EOBUWIE__';
export const STORAGE_EXPIRES_KEY = 'expires';
export const STORAGE_EXPIRES_DATA_KEY = 'data';
export const SYNERISE_UUID_COOKIE_NAME = '_snrs_uuid';
export const COOKIES_MODAL_COOKIE_NAME = 'cookies_modal';
export const COOKIES_POLICY_COOKIE_NAME = 'cookies_policy';
export const COOKIES_PERSONAL_DATA_COOKIE_NAME = 'cookies_personal_data';
export const GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME = '_ga';
export const COOKIES_IS_PROMOTION_WIDGET_ENABLED =
    'cookies_is_promotion_widget_enabled';
export const SEARCH_HISTORY_KEY = 'search_history';
export const LOCAL_WISHLIST_KEY = 'local_wishlist';
export const GUEST_CART_ID_KEY = 'guest_cart_id';
export const USER_CART_ID_KEY = 'user_cart_id';
export const USER_FIRST_VISIT_KEY = 'first_visit';
export const CHECKOUT_ORDER_KEY = 'order';
export const RECENTLY_VIEWED_PRODUCTS_KEY = 'recently_viewed_products';
export const CUSTOM_REDIRECT_AFTER_RESET_PASSWORD_KEY =
    'custom_redirect_after_reset_password';
export const CHECKOUT_LOCAL_STORAGE_KEY = 'checkout';
export const SCROLL_BEHAVIOR_CHANGED_KEY = 'scroll-behavior-changed';
export const HIDE_UNPAID_ORDERS_STATUS = 'hide-unpaid-orders-status';
export const LOGIN_METHOD = 'login-method';
export const ORIGINAL_REFERRER_KEY = 'original-referrer';
export const AVATAR_ID = 'avatar-id';
export const IS_NEW_AVATAR_ID = 'is-new-avatar-id';
export const FALLBACK_AVATAR_ID = 'fallback-avatar-id';
export const SESSION_CLIENT_ID = 'session-client-id';
export const GUEST_ESIZEME_SCANS_KEY = 'guest-esizeme-scans';
export const SELECTED_GUEST_ESIZEME_SCAN_KEY = 'selected-guest-esizeme-scan';
export const SELECTED_CUSTOMER_ESIZEME_SCAN_KEY =
    'selected-customer-esizeme-scan';
export const CUSTOMER_COMMUNICATION_AGREEMENT_KEY =
    'temp_customer_communication_agreement';
export const REDIRECT_TO_INVITE_FRIEND_PAGE_AFTER_LOGIN =
    'redirect-to-invite-friend-page-after-login';
export const REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN =
    'redirect-to-customer-page-after-login';
export const SESSION_KEY = 'session-key';
export const EMAIL_FROM_MAGIC_LINK = 'email-from-magic-link';
export const REDIRECT_PATH_AFTER_AUTH = 'redirect-path-after-auth';
export const PAGE_VIEWS_KEY = 'page-views';
export const MODAL_NEWSLETTER_VIEWS_KEY = 'modal-newsletter-views';
export const MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY =
    'modal-newsletter-last-view-expiration';

export const IS_MOBILE_WEB_VIEW = 'is-mobile-web-view';

export const CONSENTS_ITEMS_KEY = 'items_consents_codes';
export const GTM_CATEGORIES_STORAGE_KEY = 'categories_storage';
export const GTM_PROVIDERS_STORAGE_KEY = 'providers_storage';
export const GTM_CONSENTS_ACCEPTED_KEY = 'consents_accepted';
export const HIDE_MODAL_CONSENTS_KEY = 'hide_modal_consents';

export const WEB_PUSH_SUBSCRIBE_STATUS = 'web-push-subscribe-status';
export const WEB_PUSH_SUBSCRIBED = 'subscribed';
export const WEB_PUSH_UNSUBSCRIBED = 'unsubscribed';

export const RECOMMENDATION_SIZE_FILTER_KEY = 'recommendation_size_filter';

export const HIDE_LOYALTY_CLUB_BIRTHDATE_BAR =
    'hide-loyalty-club-birthdate-bar';

export const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;
export const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR;
export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * 7;
export const SECONDS_IN_DAY = 60 * 60 * 24;
export const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;

export const STORAGE_TYPE_LOCAL = 'localStorage';
export const STORAGE_TYPE_SESSION = 'sessionStorage';
export const STORAGE_TYPE_DEFAULT = STORAGE_TYPE_LOCAL;

export const STORAGE_TYPES = {
    STORAGE_TYPE_LOCAL,
    STORAGE_TYPE_SESSION,
};

export const COOKIE_NAMES = [
    COOKIES_MODAL_COOKIE_NAME,
    COOKIES_POLICY_COOKIE_NAME,
    COOKIES_PERSONAL_DATA_COOKIE_NAME,
];
