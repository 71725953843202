import { MODIVO_CLUB_GOLD_PARENT_SKU } from '@modivo-club-configs/modivo-club';

const MODIVO_CLUB_TEST_NAME = 'eob_all_modivo_club_1';
const MODIVO_CLUB_DEV_MODE_NAME = 'dev_modivo_club';
const TEST_ON = 'on';

export const isModivoClubTestEnabled = abTests => {
    return abTests.getVariant(MODIVO_CLUB_TEST_NAME) === TEST_ON;
};

export const isModivoClubGoldCardSku = sku => {
    return sku === MODIVO_CLUB_GOLD_PARENT_SKU;
};

export const isModivoClubDevModeEnabled = abTests => {
    return abTests.getVariant(MODIVO_CLUB_DEV_MODE_NAME) === TEST_ON;
};
