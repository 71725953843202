import {
    ACTIVE_ATTRIBUTE_NAME,
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    CLOTHES_SIZE_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DISCOUNT_ATTRIBUTE_NAME,
    FASON_ATTRIBUTE_NAME,
    FINAL_PRICE_ATTRIBUTE_NAME,
    FOOTWEAR_SIZE_ATTRIBUTE_NAME,
    FOOTWEAR_WIDTH_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    MODEL_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    OCCASION_ATTRIBUTE_NAME,
    PRICE_ATTRIBUTE_NAME,
    PRODUCER_ATTRIBUTE_NAME,
    PRODUCT_CARD_SIZE_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SERIES_NAME_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    SPECIAL_ACTION_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    TECHNOLOGY_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    VIDEO_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
    OMNIBUS_PRICE_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import {
    TRUE_SIZE_TYPE_CHILD,
    TRUE_SIZE_TYPE_FEMALE,
    TRUE_SIZE_TYPE_MALE,
} from '@configs/esizeme';

export const DEFAULT_SEARCH_SELECT_FIELDS = [
    ACTIVE_ATTRIBUTE_NAME,
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    CLOTHES_SIZE_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DISCOUNT_ATTRIBUTE_NAME,
    FASON_ATTRIBUTE_NAME,
    FINAL_PRICE_ATTRIBUTE_NAME,
    FOOTWEAR_SIZE_ATTRIBUTE_NAME,
    FOOTWEAR_WIDTH_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    MODEL_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    OCCASION_ATTRIBUTE_NAME,
    PRICE_ATTRIBUTE_NAME,
    PRODUCER_ATTRIBUTE_NAME,
    PRODUCT_CARD_SIZE_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SERIES_NAME_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    SPECIAL_ACTION_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    TECHNOLOGY_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    VIDEO_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
    OMNIBUS_PRICE_ATTRIBUTE_NAME,
    TRUE_SIZE_TYPE_FEMALE,
    TRUE_SIZE_TYPE_MALE,
    TRUE_SIZE_TYPE_CHILD,
];
