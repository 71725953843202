import { HttpClient } from '@eobuwie/nuxt-http';

import {
    HTTP_CLIENT_ENDPOINT_NAME_ADS,
    HTTP_CLIENT_ENDPOINT_NAME_AUTH,
    HTTP_CLIENT_ENDPOINT_NAME_AVATAR,
    HTTP_CLIENT_ENDPOINT_NAME_SMAUG,
    HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE,
    HTTP_CLIENT_ENDPOINT_NAME_SEOMATIC,
    HTTP_CLIENT_ENDPOINT_NAME_LOYALTY,
    HTTP_CLIENT_ENDPOINT_NAME_SEO_LINK_SERVICE,
    HTTP_CLIENT_ENDPOINT_NAME_SEARCH,
    HTTP_CLIENT_ENDPOINT_NAME_PREPAID,
} from '@configs/http-client';
import {
    CHANNEL_HEADER,
    APP_NAME_HEADER,
    getAppVersionHeader,
    PLATFORM_HEADER,
} from '@configs/http-headers';
import { PREPAID_CHANNEL_HEADER } from '@configs/pre-paid';

import { isTTFBTestOn } from '@assets/performance';
import { getInterceptors } from '@assets/nuxt-http/interceptors';
import { getSearchAbtestHeader } from '@search/utils/ab-tests';

export default (
    { store, env, app, $config, $errorHandler, $avatar },
    inject
) => {
    const {
        authEndpoint,
        httpClientDebug,
        avatarEndpoint,
        adsEndpoint,
        adsEndpointTimeout,
        smaugEndpoint,
        searchServiceEndpoint,
        seomaticEndpoint,
        loyaltyEndpoint,
        seoLinksServiceEndpoint,
        searchEndpoint,
        prepaidEndpoint,
    } = $config;

    const { $abTests, $mobileApp, router, $cookies, $t, $correlation } = app;

    const helpers = {
        store,
        $mobileApp,
        $cookies,
        $router: router,
        $config,
        $abTests,
        $errorHandler,
        $t,
        $correlation,
        $avatar,
    };

    const shouldMeasurePerformance = isTTFBTestOn($abTests);

    const httpClient = new HttpClient({
        endpoints: {
            [HTTP_CLIENT_ENDPOINT_NAME_AUTH]: {
                uri: authEndpoint,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_AVATAR]: {
                uri: avatarEndpoint,
                context: {
                    useAuthorization: true,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_ADS]: {
                uri: adsEndpoint,
                useErrorCallback: false,
                timeout: adsEndpointTimeout,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_SMAUG]: {
                uri: smaugEndpoint,
                useErrorCallback: true,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE]: {
                uri: searchServiceEndpoint,
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_SEOMATIC]: {
                uri: seomaticEndpoint,
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_LOYALTY]: {
                uri: loyaltyEndpoint,
                useErrorCallback: false,
                context: {
                    useAuthorization: true,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_SEO_LINK_SERVICE]: {
                uri: seoLinksServiceEndpoint,
                timeout: 500,
                context: {
                    useAuthorization: false,
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_SEARCH]: {
                uri: searchEndpoint,
                context: {
                    useAuthorization: false,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...getSearchAbtestHeader($abTests),
                },
            },
            [HTTP_CLIENT_ENDPOINT_NAME_PREPAID]: {
                uri: prepaidEndpoint,
                context: {
                    useAuthorization: true,
                },
                headers: {
                    ...PREPAID_CHANNEL_HEADER,
                },
            },
        },
        headers: {
            store: app.i18n.locale,
            ...CHANNEL_HEADER,
        },
        debug: httpClientDebug,
        interceptors: getInterceptors(shouldMeasurePerformance, helpers),
    });

    inject('httpClient', httpClient);
};
