import { MegatronHttpClient } from '@eobuwie/nuxt-http';

import {
    MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST,
    MEGATRON_CLIENT_ENDPOINT_NAME_OMNIBUS,
    MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING,
    MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE,
    MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH,
    MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT,
    MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG,
    MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH,
} from '@configs/megatron-client';
import {
    CHANNEL_HEADER,
    APP_NAME_HEADER,
    PLATFORM_HEADER,
    getAppVersionHeader,
} from '@configs/http-headers';

import { getSearchAbtestHeader } from '@search/utils/ab-tests';

import { getRmaMegatronConfig } from '@rma/megatron/get-rma-megatron-config';

import {
    createMegatronEndpointUrl,
    OMNIBUS_SUFFIX_PATH,
    SYNERISE_SUFFIX_PATH,
    SEARCH_SUFFIX_PATH,
    MAGENTO_SUFFIX_PATH,
    MODIVO_SMAUG_SUFFIX_PATH,
    PRODUCT_SEARCH_SUFFIX_PATH,
} from '@assets/megatron-client/create-megatron-endpoint-url';
import { isTTFBTestOn } from '@assets/performance';
import { getInterceptors } from '@assets/nuxt-http/interceptors';
import { getSerializedWhitelistedCookies } from '@assets/cookies';
import { getMegatronEndpoint } from '@assets/ab-test';

export default (
    { env, store, app, $config, $errorHandler, $cookies, req, $avatar },
    inject
) => {
    const {
        megatronEndpoint: megatronEndpointPath,
        megatronProjectName,
        megatronDebug,
    } = $config;
    const { $abTests, $mobileApp, router, $t, $correlation } = app;
    const megatronEndpoint = getMegatronEndpoint(
        $abTests,
        megatronEndpointPath
    );

    const helpers = {
        store,
        $mobileApp,
        $router: router,
        $cookies,
        $config,
        $abTests,
        $errorHandler,
        $t,
        $correlation,
        $avatar,
    };

    const cookieHeader = {};

    if (process.server && typeof req.headers.cookie === 'string') {
        const serializedWhitelistedCookies = getSerializedWhitelistedCookies(
            $cookies.nodeCookie.parse(req.headers.cookie)
        );

        if (serializedWhitelistedCookies) {
            cookieHeader.cookie = serializedWhitelistedCookies;
        }
    }

    const shouldMeasurePerformance = isTTFBTestOn($abTests);

    const {
        endpoints: rmaEndpoints,
        interceptors: rmaInterceptors,
    } = getRmaMegatronConfig({
        store,
        storeCode: app.i18n.locale,
        megatronEndpoint,
        createMegatronEndpointUrl,
    });

    const featureModulesInterceptors = [rmaInterceptors];

    const megatronClient = new MegatronHttpClient({
        endpoints: {
            [MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    megatronProjectName
                ),
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    MAGENTO_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: false,
                },
                useErrorCallback: false,
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_OMNIBUS]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    OMNIBUS_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: false,
                },
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, ''),
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    SYNERISE_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: false,
                },
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    SEARCH_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: false,
                    shouldAddXInstanceIdHeader: true,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...getSearchAbtestHeader($abTests),
                },
                useErrorCallback: false,
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    PRODUCT_SEARCH_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: false,
                    shouldAddXInstanceIdHeader: true,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...getSearchAbtestHeader($abTests),
                },
                useErrorCallback: false,
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, 'eobuwie/web'),
                context: {
                    useAuthorization: true,
                    shouldAddXInstanceIdHeader: true,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                },
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG]: {
                uri: createMegatronEndpointUrl(
                    megatronEndpoint,
                    MODIVO_SMAUG_SUFFIX_PATH
                ),
                context: {
                    useAuthorization: true,
                    shouldAddXInstanceIdHeader: true,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                },
            },
            ...rmaEndpoints,
        },
        headers: {
            store: app.i18n.locale,
            ...CHANNEL_HEADER,
            ...cookieHeader,
        },
        debug: megatronDebug,
        interceptors: getInterceptors(
            shouldMeasurePerformance,
            helpers,
            featureModulesInterceptors
        ),
        useKeepAlive: process.server,
    });

    inject('megatronClient', megatronClient);
};
