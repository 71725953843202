import { calculateAverageFromRatings } from '@assets/reviews';
import { getChosenSizeLabel, getVariantKey } from '@assets/product';

const BELONG_TYPES = {
    MIX: 'mix',
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

const OWN_BRANDS = [
    'Americanos',
    'Creole',
    'Eva Longoria',
    'Eva Minge',
    'Rage Age',
    'Simple',
    'Sprandi',
    'Togoshi',
];

const BRAND_TYPE = {
    OWN_BRAND: 'own brand',
    FOREIGN_BRAND: 'foreign brand',
};

const EMPTY_VALUE = 'brak';
const ONE_SIZE = 'one size';
const MULTI_SIZE = 'multi size';

export default class DataLayerProduct {
    constructor({
        discountedPrice,
        position,
        product = {},
        quantity = '',
        reviews = {},
        selectedPaymentMethod = '',
        shippingMethod,
        selectedPaymentType = '',
        esizemeChosenSizeLabel = 'none',
        variantId = '',
        promotedType = '',
    }) {
        this.discountedPrice = discountedPrice;
        this.position = position;
        this.product = product;
        this.quantity = quantity;
        this.reviews = reviews;
        this.selectedPaymentMethod = selectedPaymentMethod;
        this.shippingMethod = shippingMethod;
        this.selectedPaymentType = selectedPaymentType;
        this.esizemeChosenSizeLabel = esizemeChosenSizeLabel;
        this.variantId = variantId;
        this.chosenSize = getVariantKey(product.variants, variantId);
        this.chosenSizeLabel = getChosenSizeLabel(product, this.chosenSize);
        this.promotedType = promotedType;
    }

    getChosenSizeId() {
        return `${this.product.analytics.sku}${
            this.variantId ? `-${this.chosenSize.replaceAll('_', '.')}` : ''
        }`;
    }

    getShippingMethod() {
        return this.shippingMethod
            ? `shipping_method:${this.shippingMethod}`
            : '';
    }

    getCategoriesString() {
        const { categories, localeDefault } = this.product.analytics;
        const firstCategories = categories?.[0];

        if (
            !Array.isArray(firstCategories) ||
            firstCategories.some(
                category => !category.translations[localeDefault]
            )
        ) {
            return '';
        }

        return firstCategories
            .slice(1)
            .map(category => category.translations[localeDefault].label)
            .join('/');
    }

    variantsInStock() {
        return Object.values(this.product.analytics.variants).reduce(
            (all, variant) => {
                const variantQuantity = parseInt(variant.stock_quantity, 10);

                if (variantQuantity > 0) {
                    all.totalCount += variantQuantity;
                    all.sizes.push(variant.size);
                }

                return all;
            },
            {
                totalCount: 0,
                sizes: [],
            }
        );
    }

    getTotalCount() {
        return this.reviews?.totalCountAll ?? '';
    }

    getAverageRating() {
        return calculateAverageFromRatings(this.reviews?.averageRatings);
    }

    getPremiumValue() {
        return this.product.analytics.isPremium ? 'Premium' : 'NonPremium';
    }

    getIsInStockValue() {
        return this.variantsInStock().totalCount > 0
            ? 'In stock'
            : 'Out of stock';
    }

    getPromotionalPrice() {
        const defaultAmount = this.product.analytics.price.promotional.amount;

        return this.chosenSizeLabel
            ? defaultAmount
            : `${defaultAmount}-${defaultAmount}`;
    }

    getDiscountedPrice() {
        return (
            this.discountedPrice ||
            this.product.analytics.price.promotional.amount
        );
    }

    getBadges() {
        const { badges } = this.product.analytics;

        if (Array.isArray(badges)) {
            return badges.map(badge => badge.label).join('/');
        }

        return Object.values(badges)
            .map(badge => badge.label)
            .join('/');
    }

    getStickerType() {
        if (!this.product.promoSticker) {
            return '';
        }

        const { isHotDeal, code, formattedContent } = this.product.promoSticker;

        const type = isHotDeal ? 'hot deal' : 'standard';

        return `typ: ${type} | ${formattedContent} | ${code || ''}`;
    }

    getIsEsizemeScanWithSizeType() {
        const sizeType = this.product.isOneSize ? ONE_SIZE : MULTI_SIZE;

        const { hasTrueSize } = this.product.analytics;

        return `${sizeType} | esizeme: ${hasTrueSize}`;
    }

    allProductVariants() {
        return Object.values(this.product.analytics.variants)
            .map(({ size }) => size)
            .join(',');
    }

    static getBrandType(brandName) {
        const isOwnBrand = OWN_BRANDS.includes(brandName);

        return `${BELONG_TYPES.INTERNAL}:${
            isOwnBrand ? BRAND_TYPE.OWN_BRAND : BRAND_TYPE.FOREIGN_BRAND
        }`;
    }

    static getIsSponsored(isSponsored) {
        return isSponsored ? 'sponsored' : 'normal';
    }

    static buildResult(data) {
        const {
            badges = '',
            belongsType = '',
            brand = '',
            brandName = '',
            brandType = '',
            category = '',
            chosenSizeId = '',
            chosenSizeLabel = '',
            colorVariant = '',
            discountedPrice = '',
            fason = '',
            id = '',
            isInStockValue = '',
            promotedType,
            metric1 = '',
            metric2 = '',
            metric3 = '',
            model = '',
            name = '',
            position,
            premiumValue = '',
            price = '',
            promotionalPrice = '',
            purpose = '',
            quantity = '',
            regularPrice = '',
            omnibusPrice = '',
            reviewsTotalCount = '',
            selectedPaymentMethod = '',
            seriesName = '',
            sexName = '',
            shippingMethods = '',
            sizesInStockCount = '',
            stickerType = '',
            esizemeScan = '',
            style = '',
            variantsInStockSizes = '',
            variantsInStockTotalCount = '',
            reviews = `${EMPTY_VALUE}|{EMPTY_VALUE}`,
            allProductVariants = '',
            selectedPaymentType = '',
            hasEsizemeScan = false,
            esizemeChosenSizeLabel,
            isOneSize = false,
            hasMultiOffer = false,
            mainImageType = '',
            merchant = 'eobuwie',
            index,
        } = data;

        const result = {
            id,
            name,
            price,
            brand,
            category,
            variant: colorVariant,
            quantity,
            index,
            dimension4: fason,
            dimension5: premiumValue,
            dimension13: reviewsTotalCount,
            dimension14: sizesInStockCount,
            dimension16: isInStockValue,
            dimension26: regularPrice,
            dimension30: chosenSizeId,
            dimension31: chosenSizeLabel,
            dimension32: brandName, // @todo replace with other attribute
            dimension33: model,
            dimension34: style,
            dimension35: sexName,
            dimension36: purpose,
            dimension37: seriesName,
            dimension38: '', // @todo fill ex. ozdoby, np. 'cekiny'
            dimension39: '', // @todo fill ex. projektant, np. 'Gigi Hadid'
            dimension40: variantsInStockSizes,
            dimension41: badges,
            dimension42: variantsInStockTotalCount,
            // eslint-disable-next-line max-len
            dimension43: '', // @todo fill materiał np. 'zamsz' (mateiral_odziez ale co dla innego typu produktów?)
            dimension44: '', // @todo fill kolekcja, np 'S.Oliver Black Label'
            dimension64: reviews,
            dimension69: allProductVariants,
            dimension95: esizemeScan,
            dimension98: omnibusPrice,
            dimension101: belongsType,
            dimension102: shippingMethods,
            dimension104: promotionalPrice,
            dimension103: merchant,
            dimension105: discountedPrice,
            dimension108: selectedPaymentMethod,
            dimension109: brandType,
            dimension110: selectedPaymentType,
            dimension112: mainImageType,
            dimension130: promotedType,
            dimension131: stickerType,
            metric1,
            metric2,
            metric3,
            multiSize: !isOneSize,
            multiOffer: hasMultiOffer,
            hasEsizemeScan,
            esizemeChosenSizeLabel,
        };

        if (position) {
            result.position = position;
        }

        return result;
    }

    static buildSimpleResult(product) {
        const {
            sku: id,
            name,
            price,
            brandName,
            quantity,
            fason = '',
            index = 0,
            categories = [],
        } = product || {};

        return {
            id,
            name,
            price: price?.promotional?.amount || '',
            brand: brandName,
            category: categories[0]?.split('/').slice(1).join('/') || '',
            index: index + 1 || 1,
            quantity,
            fason,
        };
    }

    build() {
        if (!this.product.analytics) {
            return DataLayerProduct.buildSimpleResult(this.product);
        }

        const {
            brandName,
            color,
            fason,
            model,
            name,
            price,
            purpose,
            seriesName,
            sexName,
            sku,
            style,
            isSponsored,
            mainImageType,
        } = this.product.analytics;

        const variantsInStock = this.variantsInStock();
        const discountedPrice = this.getDiscountedPrice();

        const reviewsTotalCount = this.getTotalCount();
        const averageRating = this.getAverageRating();

        const reviews = `${averageRating || ''}|${
            reviewsTotalCount || EMPTY_VALUE
        }`;

        const data = {
            badges: this.getBadges(),
            belongsType: BELONG_TYPES.INTERNAL,
            brand: brandName,
            brandName,
            brandType: DataLayerProduct.getBrandType(brandName),
            category: this.getCategoriesString(),
            chosenSizeId: this.getChosenSizeId(),
            chosenSizeLabel: this.chosenSizeLabel,
            colorVariant: color,
            discountedPrice,
            fason,
            index: this.product.index + 1 || 1,
            id: sku,
            isInStockValue: this.getIsInStockValue(),
            metric1: price.regular.amount,
            metric2: price.promotional.amount,
            metric3: discountedPrice,
            model,
            name,
            position: this.position,
            premiumValue: this.getPremiumValue(),
            price: price.promotional.amount,
            promotionalPrice: this.getPromotionalPrice(),
            purpose: purpose.join(','),
            quantity: this.quantity,
            regularPrice: String(price.regular.amount),
            omnibusPrice: String(price.omnibus.amount),
            reviewsTotalCount,
            selectedPaymentMethod: this.selectedPaymentMethod,
            seriesName,
            sexName,
            shippingMethods: this.getShippingMethod(),
            sizesInStockCount: variantsInStock.sizes.length,
            stickerType: this.getStickerType(),
            esizemeScan: this.getIsEsizemeScanWithSizeType(),
            style: style.join(','),
            variantsInStockSizes: variantsInStock.sizes.join(','),
            variantsInStockTotalCount: variantsInStock.totalCount,
            promotedType:
                this.promotedType ||
                DataLayerProduct.getIsSponsored(isSponsored),
            reviews,
            allProductVariants: this.allProductVariants(),
            selectedPaymentType: this.selectedPaymentType,
            hasEsizemeScan: this.product.analytics.hasTrueSize,
            esizemeChosenSizeLabel: this.esizemeChosenSizeLabel,
            isOneSize: this.product.isOneSize,
            mainImageType,
        };

        if (this.position) {
            data.position = this.position;
        }

        return DataLayerProduct.buildResult(data);
    }
}
