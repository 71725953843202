import {
    getEngineVariant,
    addCategoriesTranslations,
    JESSICA,
} from '@search/assets/search-service';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import { prepareSearchData } from '@search/utils/prepare/prepare-search-data';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { SEARCH_ENGINE } from '@localeConfig/keys';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareSearchDataFromCorrectEngine = async ({
    $asyncServices,
    $errorHandler,
    $abTests,
    searchRequestParams,
    $getLocaleConfigByKey,
}) => {
    const engine = getEngineVariant(
        $abTests,
        $getLocaleConfigByKey(SEARCH_ENGINE)
    );

    if (!engine || engine === JESSICA) {
        return prepareSearchData({
            searchRequestParams,
            $asyncServices,
            $errorHandler,
        });
    }

    const { locale } = searchRequestParams;

    const promises = [
        $asyncServices.use(
            CATALOG_SERVICE_NAME,
            CATALOG_SERVICE_METHODS.GET_SEARCH_SERVICE_DATA,
            { ...searchRequestParams, engine }
        ),
    ];

    if (locale !== DEFAULT_LOCALE) {
        promises.push(
            $asyncServices.use(
                CATALOG_SERVICE_NAME,
                CATALOG_SERVICE_METHODS.GET_CATEGORIES_TRANSLATIONS,
                {
                    categories: searchRequestParams.categories,
                    locale,
                    getLocale: DEFAULT_LOCALE,
                }
            )
        );
    }

    const [
        { data, error, status, originalResponse },
        categories = null,
    ] = await Promise.all(promises);

    if (!data) {
        $errorHandler.captureError(
            new Error(error?.message || 'UNKNOWN_ERROR', {
                cause: error,
            }),
            {
                [ERROR_ACTION_TAG_NAME]: 'getSearchServiceData',
            },
            {
                searchParams: searchRequestParams,
                data,
                error,
                status,
                originalResponse,
            }
        );

        return prepareSearchData({
            searchRequestParams,
            $asyncServices,
            $errorHandler,
            fallbackFrom: JESSICA,
        });
    }

    if (error) {
        const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
            cause: error,
        });

        $errorHandler.captureError(
            errorToThrow,
            {
                [ERROR_ACTION_TAG_NAME]: 'getSearchServiceData',
            },
            {
                searchParams: searchRequestParams,
                data,
                error,
                status,
                originalResponse,
            }
        );

        throw errorToThrow;
    }

    const {
        navigation = {},
        navigation: { categories: [category = null] = [] } = {},
    } = data;

    if (category && !category.translations[DEFAULT_LOCALE] && categories) {
        addCategoriesTranslations(navigation, categories);
    }

    const {
        total = null,
        totalFormatted = null,
        search_query: searchQuery = {},
        products: searchProducts = [],
        filters,
        sort_options: sortOptions = [],
        navigation: { bread_crumbs: { items: breadcrumbs = [] } = {} } = {},
        engine: responseEngine,
        hasSuggested = false,
    } = data;

    return {
        total,
        totalFormatted,
        searchQuery,
        searchProducts,
        filters,
        navigation,
        sortOptions,
        breadcrumbs,
        responseEngine,
        hasSuggested,
    };
};
