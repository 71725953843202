export const MODIVO_CLUB_GOLD_SKU = '2230084961333-NOSIZE';
export const MODIVO_CLUB_GOLD_PARENT_SKU = '2230084961333';

export const MODIVO_CLUB_GOLD_ADD_TO_CART_DATA = {
    data: {
        quantity: 1,
        sku: MODIVO_CLUB_GOLD_SKU,
    },
    parent_sku: MODIVO_CLUB_GOLD_PARENT_SKU,
};

const PSB_MODIVO_CLUB_CUSTOMER_FAQ_TEXT_PREFIX =
    'psb_modivo_club_customer_faq_text_';
const MODIVO_CLUB_CUSTOMER_FAQ_LIMIT = 5;

export const IS_MODIVO_CLUB_ELEMENT_ENABLED = false;

export const MODIVO_CLUB_GIFTCARD_PAGE_CMS_BLOCK_ID =
    'psb_modivo_club_giftcard_page';

export const MODIVO_CLUB_CUSTOMER_FAQ_TEXT_BLOCKS_IDS = Array.from(
    { length: MODIVO_CLUB_CUSTOMER_FAQ_LIMIT },
    (element, index) =>
        `${PSB_MODIVO_CLUB_CUSTOMER_FAQ_TEXT_PREFIX}${index + 1}`
);

export const MODIVO_CLUB_CUSTOMER_PAGE_CMS_BLOCKS_IDS = [
    ...MODIVO_CLUB_CUSTOMER_FAQ_TEXT_BLOCKS_IDS,
];

export default {
    MODIVO_CLUB_CUSTOMER_PAGE_CMS_BLOCKS_IDS,
};
