import { getCountryCodeFromLocale } from '@assets/locale';

const REGEXP_AB_TEST_WITH_VERSION = /^.+_\d+(\.\d+)?$/;

export const VARIANT_ON = 'b_on';

const findVersion = name => parseFloat(name.split('_').pop());

export const findHighestTest = ({ abTests }, testName) => {
    const sortedAbTests = Object.entries(abTests)
        .filter(([name]) => REGEXP_AB_TEST_WITH_VERSION.test(name))
        .map(([name, variant]) => ({
            name: name.split('_').slice(0, -1).join('_'),
            version: findVersion(name),
            variant,
            componentSuffix: variant.split('_')[0],
        }))
        .filter(test => test.name === testName)
        .sort((t1, t2) => t2.version - t1.version);

    return sortedAbTests?.[0];
};

export const createTestName = (componentName, country = 'all') =>
    `eob_${country}_${componentName}`;

export const findCountryTest = ($abTests, name, country) =>
    findHighestTest($abTests, createTestName(name, country));

export const findAbTest = ($abTests, name, locale) => {
    const countryTest = findCountryTest(
        $abTests,
        name,
        getCountryCodeFromLocale(locale)
    );
    const allCountriesTest = findCountryTest($abTests, name, 'all');

    return countryTest || allCountriesTest;
};

export const isTestEnabled = ($abTests, locale, name) =>
    findAbTest($abTests, name, locale)?.variant === VARIANT_ON;
