import injectLazyPluginSingleton from '@analytics-assets/inject-lazy-plugin-singleton';

import EventContext from './assets/EventContext';

const options = JSON.parse(`{
  "speedCurve": false,
  "debug": false
}`);
const transformEventContext = (context, store) => {
                        return new Promise((resolve, reject) => {
                            const {
                                state,
                                getters,
                                $storage,
                                $cookies,
                            } = store;
                            const loginMethod = getters['customer/isLoggedIn']
                                ? $storage.getItem('login-method')
                                : '';
                            const getCustomerHashedEmail =
                                getters['customer/customerHashedEmail'];

                            getCustomerHashedEmail()
                                .then(hashedEmail => {
                                    const transformedContext = {
                                        ...context,
                                        userAuth: {
                                            userId:
                                                getters[
                                                    'customer/customerUuid'
                                                ],
                                            userType:
                                                getters['customer/userType'],
                                            accountId: hashedEmail,
                                            loginMethod,
                                            avatarId:
                                                $cookies.get(
                                                    '__EOBUWIE__avatar-id'
                                                ) || '',
                                            modivoClubStatus:
                                                state.modivoClub
                                                    .membershipStatus,
                                            modivoClubCashback:
                                                getters[
                                                    'modivoClub/cashbackTotalFundsAmount'
                                                ],
                                        },
                                    };

                                    resolve(transformedContext);
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        }).catch(err => {
                            throw err;
                        });
                    };

options.transformEventContext = transformEventContext;

export default ({ app, env, store }, inject) => {
    injectLazyPluginSingleton(
        inject,
        'analytics',
        () => import('./models/Analytics' /* webpackChunkName: 'analytics' */),
        [app, env, options, store, new EventContext()]
    );
};
