var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},_vm._l((_vm.messages),function({
            id,
            type,
            additionalText,
            title,
            text,
            action,
            hasIcon,
        }){return _c('BaseAlertRow',{key:`message-${id}`,staticClass:"messages-alert",attrs:{"id":id,"type":type,"action-handler":action.handler,"action-url":action.url,"action-text":action.label,"has-icon":hasIcon},scopedSlots:_vm._u([(!!additionalText)?{key:"additional-text",fn:function(){return [_vm._v("\n            "+_vm._s(additionalText)+"\n        ")]},proxy:true}:null],null,true)},[(title)?_c('p',[_vm._v(_vm._s(title))]):_vm._e(),_vm._v("\n        "+_vm._s(text)+"\n        ")])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }