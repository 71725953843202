import {
    HOME_PAGE_NAME,
    PRODUCT_PAGE_NAME,
    ORDER_PAGE_NAME,
    NEWSLETTER_PAGE_NAME,
    CONTACT_PAGE_NAME,
    BRANDS_PAGE_NAME,
    ADD_RETURN_PAGE_NAME,
    AFTER_ADD_PAGE_RETURN_NAME,
    CMS_PAGE_NAME,
    MODIVO_CLUB_GIFTCARD_PAGE_NAME,
} from '@router/names';

import {
    CATALOG_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
} from '@search/routing/names';

export const ROUTES_USING_FETCH_IN_PARALLEL = [
    HOME_PAGE_NAME,
    PRODUCT_PAGE_NAME,
    CATALOG_PAGE_NAME,
    ORDER_PAGE_NAME,
    NEWSLETTER_PAGE_NAME,
    CONTACT_PAGE_NAME,
    BRANDS_PAGE_NAME,
    ADD_RETURN_PAGE_NAME,
    AFTER_ADD_PAGE_RETURN_NAME,
    CMS_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
    MODIVO_CLUB_GIFTCARD_PAGE_NAME,
];
