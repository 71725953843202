export const ADD_RETURN_PAGE_NAME = 'add-return';
export const AFTER_ADD_PAGE_RETURN_NAME = 'after-add-return';
export const BRANDS_PAGE_NAME = 'brands';
export const CHECKOUT_CART_PAGE_NAME = 'checkout-cart';
export const CHECKOUT_PAGE_NAME = 'checkout';
export const CHECKOUT_REPAYMENT_NAME = 'checkout-repayment';
export const CHECKOUT_SUCCESS_PAGE_NAME = 'checkout-success';
export const CHECKOUT_ACCOUNT_PAGE_NAME = 'checkout-account';
export const CMS_PAGE_NAME = 'cms';
export const CONTACT_PAGE_NAME = 'contact';
export const CUSTOMER_ESIZEME_NAME = 'customer/esizeme';
export const CUSTOMER_ACCOUNT_AGREEMENTS_NAME = 'customer/agreements';
export const CUSTOMER_ACCOUNT_ORDER_VIEW_NAME = 'customer/orders/order-view';
export const CUSTOMER_ACCOUNT_ORDERS_NAME = 'customer/orders';
export const CUSTOMER_ACCOUNT_RETURN_NAME = 'customer/add-return';
export const CUSTOMER_ACCOUNT_RETURNS_NAME = 'customer/returns';
export const CUSTOMER_ACCOUNT_REVIEWS_NAME = 'customer/reviews';
export const CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME = 'customer/modivo-club';
export const CUSTOMER_ADDRESSES_NAME = 'customer/addresses';
export const CUSTOMER_DETAILS_NAME = 'customer/details';
export const CUSTOMER_LOYALTY_CLUB_NAME = 'customer/loyalty-club';
export const CUSTOMER_PAGE_NAME = 'customer';
export const CUSTOMER_ACCOUNT_INVITE_FIEND_NAME = 'customer/invite-friend';
export const HOME_PAGE_NAME = 'homepage';
export const LOGIN_PAGE_NAME = 'login';
export const NEW_PASSWORD_PAGE_NAME = 'new-password';
export const NEWSLETTER_PAGE_NAME = 'newsletter';
export const NEWSLETTER_SMS_UNSUBSCRIBE_PAGE_NAME = 'sms-unsubscribe';
export const NOT_FOUND_NAME = 'not-found';
export const NOT_SUPPORTED_PAGE_NAME = 'not-supported';
export const ORDER_PAGE_NAME = 'order';
export const ORDER_STATUS_NAME = 'order-status';
export const PACKAGE_TRACKING_PAGE_NAME = 'package-tracking';
export const PAGE_BUILDER_NAME = 'pb';
export const PRODUCT_PAGE_NAME = 'product';
export const REGISTER_PAGE_NAME = 'register';
export const RESET_PASSWORD_PAGE_NAME = 'reset-password';
export const RESET_PASSWORD_NEW_PAGE_NAME = 'reset-password-new';
export const WISHLIST_PAGE_NAME = 'wishlist';
export const GUEST_INVITE_FRIEND_NAME = 'guest-invite-friend';
export const GUEST_LOYALTY_CLUB_NAME = 'guest-loyalty-club';
export const INVITE_FRIEND_REF_LINK_NAME = 'invite-friend-ref-link-name';
export const INVITE_FRIEND_INACTIVE_REF_LINK_NAME =
    'invite-friend-inactive-ref-link-name';
export const WEB_LINKS_PAGE_NAME = 'web-links';
export const MOBILE_TOKEN_EXPIRED_PAGE_NAME = 'token-expired';
export const MODIVO_CLUB_NAME = 'modivo-club';
export const MODIVO_CLUB_GIFTCARD_PAGE_NAME = 'modivoClubGiftCard';
