import { GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME } from '@configs/storage';
import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

export const SEARCH_SERVICE_ENGINE_COOKIE_NAME =
    'eob_all_plp_searchServiceEngine';
export const USE_SEARCH_SORTING_VARIANTS = 'eob_all_plp_sorting_variants';

export const COOKIE_AUTOCOMPLETE_POC_CATEGORIES =
    'eob_all_plp_autocompletePocCategories';

export const SYNERISE = 'SYNERISE';
export const GOOGLE = 'GOOGLE';
export const JESSICA = 'JESSICA';

export const SYNERISE_SEARCH_TYPE_FULL_TEXT = 'full-text-search';
export const SYNERISE_SEARCH_TYPE_AUTOCOMPLETE = 'autocomplete';

const AVAILABLE_VARIANTS = [SYNERISE, GOOGLE, JESSICA];

export const ACCESSORIES_PL = 'akcesoria';
export const BAGS_PL = 'torebki';
export const WOMENS_PL = 'damskie';
export const MENS_PL = 'meskie';
export const KIDS_PL = 'dzieciece';
export const UNISEX = 'unisex';
export const SPORT = 'sport';

export const SEARCH_SERVICE_CATEGORY_LISTINGS = [
    ACCESSORIES_PL,
    BAGS_PL,
    WOMENS_PL,
    MENS_PL,
    KIDS_PL,
    UNISEX,
    SPORT,
];

export const getSearchServiceQueryUrl = (
    searchQueryUrl,
    engine = SYNERISE,
    sortingVariant = null
) => {
    const engineInfo = engine ? `&engine=${engine}` : '';
    const sorting = sortingVariant ? `&sortingVariant=${sortingVariant}` : '';

    return `?${searchQueryUrl.split('?')[1]}${engineInfo}${sorting}`;
};

export const getEngineVariant = (abTests, searchEngine) => {
    const defaultEngine = searchEngine;

    const abTestEngine = abTests
        ?.getVariant(SEARCH_SERVICE_ENGINE_COOKIE_NAME)
        ?.split('_')[0]
        ?.toUpperCase();

    const engineVariant = abTestEngine || defaultEngine;

    return AVAILABLE_VARIANTS.includes(engineVariant) ? engineVariant : JESSICA;
};

export const getGoogleClientId = $cookies => {
    const gaCookie = $cookies.get(GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME);

    if (!gaCookie || typeof gaCookie !== 'string') {
        return undefined;
    }

    return gaCookie?.split('.').slice('-2').join('.');
};

const addCategoryTranslation = (item, translations) => {
    const { id, children = null } = item;

    item.translations[DEFAULT_LOCALE] = translations[id];

    if (children) {
        item.children = children.map(child =>
            addCategoryTranslation(child, translations)
        );
    }

    return item;
};

export const addCategoriesTranslations = (
    navigation,
    { categories, breadcrumbs, treeItems } = {}
) => {
    if (!categories || !Object.keys(categories)) {
        return;
    }

    const {
        bread_crumbs: { items } = { items: [] },
        categories: navCategories = [],
        tree: { items: branches } = { branches: [] },
    } = navigation;

    navigation.bread_crumbs.items = items.map(breadcrumb =>
        addCategoryTranslation(breadcrumb, breadcrumbs)
    );

    navigation.categories = navCategories.map(category =>
        addCategoryTranslation(category, categories)
    );

    navigation.tree.items = branches.map(branch =>
        addCategoryTranslation(branch, treeItems)
    );

    return navigation;
};
