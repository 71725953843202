export const AB_TEST_COOKIE_VARIANT_SEPARATOR = '=';
export const X_ABTEST_HEADER_NAME = 'x-abtest';

export const getAbtestHeader = $abTests => {
    if (!$abTests) {
        return;
    }

    const { abTests } = $abTests;

    const allTests = { ...abTests };

    const testEntries = Object.entries(allTests);

    if (!testEntries.length) {
        return {};
    }

    return {
        [X_ABTEST_HEADER_NAME]: testEntries
            .join('|')
            .replaceAll(',', AB_TEST_COOKIE_VARIANT_SEPARATOR),
    };
};

export const getMegatronEndpoint = ($abTests, megatronEndpointPath) => {
    const k8sMegatronEndpoint = process.server
        ? 'http://megatron-eobuwie-gcp.prod.modivo.cloud'
        : '/t-api-2';

    return $abTests.getVariant('dev_megatron_k8s') === 'on'
        ? k8sMegatronEndpoint
        : megatronEndpointPath;
};
