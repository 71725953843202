import { ERROR_CAMPAIGN_NOT_FOUND } from '@errors/synerise';
import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { AB_TEST_NAME_PREFIX } from '@configs/recommendations';
import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE } from '@configs/megatron-client';
import {
    NOT_FOUND_HTTP_CODE,
    BAD_REQUEST_HTTP_CODE,
} from '@configs/http-codes';

import {
    ERROR_ACTION_TAG_NAME,
    ERROR_SYNERISE_ERROR_CODE,
    ERROR_SYNERISE_ERROR_HTTP_STATUS,
} from '@types/Errors';
import {
    SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU,
    SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID,
} from '@types/Synerise';

import { SyneriseCampaignQuery } from '@eobuwie/synerise-recommendations';

import { FILTERS_JOINERS } from '@eobuwie/synerise-recommendations/dist/types/filters';

import { snakeCase } from '@assets/string-utility';
import { getCountryCodeFromLocale } from '@assets/locale';
import { isRecommendationsOff } from '@assets/recommendations';

export default ({
    megatronClient,
    $errorHandler,
    helpers: { abTests } = {},
}) => ({
    setApiKey(apiKey) {
        this.apiKey = apiKey;
    },

    setPlacements(placements) {
        this.placements = placements;
    },

    async getById({
        type,
        clientUUID = '',
        category = '',
        productSku = '',
        customCampaignId = '',
        locale = '',
        size = '',
    }) {
        const response = (recommendations, extras) => ({
            recommendations: recommendations || [],
            extras: extras || {},
        });

        const { apiKey } = this;

        if (!apiKey || isRecommendationsOff(abTests)) {
            return response();
        }

        const campaign =
            customCampaignId || this.getCampaignIdByType(type, locale);

        if (!campaign) {
            return response();
        }

        const syneriseQuery = new SyneriseCampaignQuery();

        syneriseQuery.setCampaign(campaign);

        if (category !== '') {
            syneriseQuery
                .setAdditionalFilter('category', category)
                .setFiltersJoiner(FILTERS_JOINERS.FILTERS_JOINER_AND);
        }

        if (size !== null && size !== '') {
            syneriseQuery
                .setAdditionalFilter('size', size)
                .setFiltersJoiner(FILTERS_JOINERS.FILTERS_JOINER_AND);
        }

        if (!SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID.includes(type)) {
            if (clientUUID === '') {
                return response();
            }

            syneriseQuery.setClientUUID(clientUUID);
        }

        if (SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU.includes(type)) {
            if (productSku.length === 0) {
                return response();
            }

            syneriseQuery.setProductSku(productSku);
        }

        const url = syneriseQuery.build();

        const { data, error, status } = await megatronClient.get(url, {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE,
            headers: {
                'x-api-key': apiKey,
            },
            useErrorCallback: false,
        });

        if (error && status >= BAD_REQUEST_HTTP_CODE) {
            const [path] = url.split('?');

            const errorCode = error?.data?.errorCode;

            let tags = {
                [ERROR_ACTION_TAG_NAME]: path,
            };

            if (
                status === NOT_FOUND_HTTP_CODE &&
                errorCode !== ERROR_CAMPAIGN_NOT_FOUND
            ) {
                return response();
            }

            tags = {
                ...tags,
                [ERROR_SYNERISE_ERROR_HTTP_STATUS]: error?.data?.httpStatus,
                [ERROR_SYNERISE_ERROR_CODE]: errorCode,
            };

            const err = error?.data?.error || 'UNKNOWN_ERROR';

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(err),
                tags,
                {
                    url,
                    payload: {
                        recommendationType: type,
                        productSku,
                        category,
                        customCampaignId,
                        uuid: clientUUID,
                    },
                    data,
                    status,
                    error,
                }
            );
        }

        return response(data?.data, data?.extras);
    },

    getTestCampaignId(type, locale) {
        const countryCode = getCountryCodeFromLocale(locale);

        return abTests?.getVariant(
            `${AB_TEST_NAME_PREFIX}${countryCode}_${snakeCase(type)}`
        );
    },

    getCampaignIdByType(type, locale) {
        const { placements } = this;

        if (!placements) {
            return '';
        }

        const testCampaign = this.getTestCampaignId(type, locale);

        return testCampaign || placements[type];
    },
});
