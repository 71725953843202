import { GOLD, STANDARD } from '@modivo-club-types/statuses';

export default {
    isModivoClubMember: state => {
        return !!state.membershipStatus;
    },

    isModivoClubGoldMember: state => {
        return state.membershipStatus === GOLD;
    },

    isModivoClubStandardMember: state => {
        return state.membershipStatus === STANDARD;
    },

    cashbackTotalFundsAmount: state => {
        return state.cashbackCardData.balance;
    },

    isCashbackAvailable: state => {
        return state.cashbackCardData.balance > 0;
    },

    cashbackCardNumber: state => {
        return state.cashbackCardData.cardNumber;
    },
};
