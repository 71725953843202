<template>
    <div class="login-register-layout">
        <BaseSimpleHeader
            v-if="!isWebViewMobile"
            class="header"
            :show-marketing-bar="showMarketingBar"
        >
            <template #top-info>
                <BaseLink class="back-button" :path="pathBackToShopping">
                    <template #icon>
                        <SvgIcon width="20px" height="20px" class="back-icon">
                            <Left />
                        </SvgIcon>
                    </template>
                    {{ $t('Back to the store') }}
                </BaseLink>
            </template>
        </BaseSimpleHeader>

        <div class="layout">
            <div class="content">
                <InviteFriendMessage
                    v-if="showInviteFriendMessage"
                    class="invite-friend-message"
                />
                <div class="columns">
                    <main class="main-column">
                        <nuxt />
                    </main>
                    <aside v-if="!isModivoClubEnabled" class="side-column">
                        <RegisterBenefits
                            :with-heading="true"
                            :is-large="true"
                        />
                    </aside>
                </div>
            </div>
        </div>

        <template v-if="!isWebViewMobile">
            <FooterFreeDelivery class="return-delivery" />
            <FooterSimple class="footer-simple" />
            <Messages />
            <ChatWrapper />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    INVITE_FRIEND_REF_LINK_NAME,
    LOGIN_PAGE_NAME,
    REGISTER_PAGE_NAME,
    RESET_PASSWORD_PAGE_NAME,
    RESET_PASSWORD_NEW_PAGE_NAME,
    NEW_PASSWORD_PAGE_NAME,
} from '@router/names';

import Left from '@static/icons/20px/left.svg?inline';

import { getPathBackToShopping } from '@assets/login-register';
import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLink from '@atoms/BaseLink/BaseLink';

import FooterSimple from '@molecules/FooterSimple/FooterSimple';
import FooterFreeDelivery from '@molecules/FooterFreeDelivery/FooterFreeDelivery';
import Messages from '@molecules/Messages/Messages';
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import InviteFriendMessage from '@molecules/InviteFriendMessage/InviteFriendMessage';
import RegisterBenefits from '@molecules/RegisterBenefits/RegisterBenefits';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseLayout from '@layouts/mixins/BaseLayout';
import MobileWebView from '@layouts/mixins/MobileWebView';

const { mapState: mapAvatarState } = createNamespacedHelpers('avatar');

export default {
    name: 'LoginRegisterLayout',

    components: {
        RegisterBenefits,
        FooterSimple,
        Messages,
        FooterFreeDelivery,
        ChatWrapper,
        Left,
        SvgIcon,
        BaseLink,
        InviteFriendMessage,
        SmartBannerWrapper,

        BaseSimpleHeader: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "base-simple-header" */ '@organisms/BaseSimpleHeader/BaseSimpleHeader'
            ),
        }),
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        ...mapAvatarState([
            'isInviteFriendFeatureEnabled',
            'isInviteFriendRegisterPageMounted',
        ]),

        showInviteFriendMessage() {
            return (
                this.shouldShowInviteFriendMessage &&
                this.isInviteFriendRegisterPageMounted
            );
        },

        showMarketingBar() {
            return ![
                LOGIN_PAGE_NAME,
                REGISTER_PAGE_NAME,
                RESET_PASSWORD_PAGE_NAME,
                RESET_PASSWORD_NEW_PAGE_NAME,
                NEW_PASSWORD_PAGE_NAME,
            ].includes(this.$route.name);
        },

        pathBackToShopping() {
            return getPathBackToShopping(this.$nuxt.context.from?.path);
        },

        shouldShowInviteFriendMessage() {
            return (
                this.isInviteFriendFeatureEnabled &&
                this.$route.name === INVITE_FRIEND_REF_LINK_NAME
            );
        },

        isModivoClubEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin with-separator {
    @apply relative;

    &:before {
        @apply border-t-1 border-border absolute left-0 top-0 mx-3;

        content: '';
        width: calc(100% - (theme('spacing.3') * 2));
    }
}

.login-register-layout {
    @apply flex flex-col;
    min-height: 100vh;

    .header {
        @apply border-none;

        &:deep(.top-info) {
            @apply p-0;
        }
    }

    .back-button {
        @apply w-full font-bold justify-center py-10p px-3 text-green1 text-s leading-s;

        &:hover,
        &:active {
            @apply no-underline;
        }
    }

    .back-icon {
        @apply text-current;
    }

    .layout {
        @apply grow;
    }

    .side-column {
        @apply hidden;
    }

    .return-delivery {
        @apply pt-4;
    }

    .footer-simple {
        @apply border-none bg-light;
    }

    @screen lg {
        .layout {
            @apply flex items-center flex-col bg-gray8 pt-4 pb-8;

            .columns {
                @apply flex items-start gap-5;
            }

            .invite-friend-message {
                @apply mb-12p;
            }
        }

        .main-column {
            @apply shrink-0 bg-light rounded-4;

            width: theme('customVariables.loginRegister.mainColumnWidth');
        }

        .side-column {
            @apply block shrink-0;

            width: theme('customVariables.loginRegister.sideColumnWidth');
        }
    }
}
</style>
