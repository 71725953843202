var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isAuthorizationModalOpen)?_c('ModalAuthorization',{on:{"close-modal":function($event){return _vm.closeModal(_vm.MODAL_AUTHORIZATION_NAME)}}}):_vm._e(),_vm._v(" "),(_vm.isInviteFriendInfoModalOpen && !_vm.isModivoClubEnabled)?_c('InviteFriendInfoModal',{attrs:{"title":_vm.$t('Only new users can use the referral link'),"description":_vm.inviteFriendInfoDescription,"button-text":_vm.$t('Recommend eobuwie')},on:{"button-click":function($event){return _vm.goToInviteFriendPage()},"close-modal":function($event){return _vm.closeModal(_vm.MODAL_INVITE_FRIEND_INFO)},"mounted":function($event){return _vm.onInviteFriendInfoModalMounted()}}}):_vm._e(),_vm._v(" "),(_vm.isInviteFriendThankYouModalOpen && !_vm.isModivoClubEnabled)?_c('InviteFriendThankYouModal',{on:{"close-modal":function($event){return _vm.closeModal(_vm.MODAL_INVITE_FRIEND_THANK_YOU)}}}):_vm._e(),_vm._v(" "),(_vm.isAddEsizemeScanModalOpen)?_c('AddEsizemeScanModal',{attrs:{"is-open":_vm.isAddEsizemeScanModalOpen},on:{"close":function($event){return _vm.closeModal(_vm.MODAL_ADD_ESIZEME_SCAN)}}}):_vm._e(),_vm._v(" "),(
            _vm.isModalAfterRegistrationBySocialMediaOpen &&
            !_vm.isInviteFriendThankYouModalOpen &&
            !_vm.isModivoClubEnabled
        )?_c('ModalAfterRegistrationBySocialMedia',{on:{"close-modal":function($event){return _vm.closeModal(_vm.MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA)}}}):_vm._e(),_vm._v(" "),(_vm.isMagicLinkAuthModalOpen && _vm.isMagicLinkEnabled)?_c('MagicLinkAuth',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_MAGIC_LINK_AUTH)}}}):_vm._e(),_vm._v(" "),(_vm.isAccountVerificationModalOpen)?_c('AccountVerificationModal',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_ACCOUNT_VERIFICATION)}}}):_vm._e(),_vm._v(" "),(_vm.shouldOpenNewsletterTriggerTooltip && !_vm.isModivoClubEnabled)?_c('NewsletterTriggerTooltip'):_vm._e(),_vm._v(" "),(_vm.shouldOpenModalNewsletter && !_vm.isModivoClubEnabled)?_c('ModalNewsletter',{attrs:{"customer-email":_vm.customerEmail},on:{"close":function($event){return _vm.closeModal(_vm.MODAL_NEWSLETTER_NAME)}}}):_vm._e(),_vm._v(" "),(_vm.isModalSniperLinkOpen && !_vm.isModivoClubEnabled)?_c('ModalSniperLink',{on:{"close":function($event){return _vm.closeSniperLinkModal()}}}):_vm._e(),_vm._v(" "),(_vm.isLoyaltyClubEnabled && !_vm.isModivoClubEnabled)?_c('JoinToLoyaltyClubModal',{attrs:{"show":_vm.isJoinToLoyaltyClubModalOpen},on:{"close":function($event){return _vm.closeModal(_vm.JOIN_TO_LOYALTY_CLUB_MODAL)}}}):_vm._e(),_vm._v(" "),(_vm.isModalModivoClubRegistrationOpen)?_c('ModivoClubRegistrationModal',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_MODIVO_CLUB_REGISTRATION)}}}):_vm._e(),_vm._v(" "),_c('NewsletterConfirmationModal',{attrs:{"show":_vm.isModalNewsletterConfirmationOpen &&
            _vm.shouldOpenModalNewsletterConfirmation &&
            !_vm.isJoinToLoyaltyClubModalOpen &&
            !_vm.isModivoClubEnabled},on:{"close":function($event){return _vm.closeModal(_vm.MODAL_NEWSLETTER_CONFIRMATION)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }