export const types = {
    SET_QUERY: 'SET_QUERY',
    SET_RESULTS: 'SET_RESULTS',
    SET_IS_QUERYING: 'SET_IS_QUERYING',
    SET_AUTOCOMPLETE_VISITED: 'SET_AUTOCOMPLETE_VISITED',
    SET_SEARCH_ENGINE_SPECIFIC_AUTOCOMPLETE:
        'SET_SEARCH_ENGINE_SPECIFIC_AUTOCOMPLETE',
    SET_SEARCH_REDIRECT: 'SET_SEARCH_REDIRECT',
    SET_CAPPING: 'SET_CAPPING',
    TOGGLE_ANALYTICS_TRIGGER: 'TOGGLE_ANALYTICS_TRIGGER',
};

export default {
    [types.SET_QUERY](state, query) {
        state.query = query;
    },

    [types.SET_IS_QUERYING](state, isQuerying) {
        state.isQuerying = isQuerying;
    },

    [types.SET_RESULTS](state, results) {
        const {
            products = [],
            categories = [],
            manufacturers = [],
            result = [],
            brands = [],
            phrases = [],
        } = results;

        state.suggestions = result;
        state.phrases = phrases;
        state.brands = [];

        if (manufacturers.length) {
            state.brands = manufacturers;
        }

        if (brands.length) {
            state.brands = brands;
        }

        state.products = products;
        state.categories = categories.slice(0, 6);
    },

    [types.SET_AUTOCOMPLETE_VISITED](state, isVisited) {
        state.autocompleteVisited = isVisited;
    },

    [types.SET_SEARCH_ENGINE_SPECIFIC_AUTOCOMPLETE](state, value) {
        state.useSearchEngineSpecificAutocomplete = value;
    },

    [types.SET_SEARCH_REDIRECT](state, redirect) {
        const { slug = null } = redirect;

        state.redirect = slug;
    },

    [types.SET_CAPPING](state, capping) {
        state.capping = capping;
    },

    [types.TOGGLE_ANALYTICS_TRIGGER](state) {
        state.analyticsTrigger = !state.analyticsTrigger;
    },
};
