import { getVariantKey } from '@assets/product';

import { COLOR_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

export const formatDiscount = value => {
    if (!value) {
        return '';
    }

    return value < 0 ? `+${value * -1}%` : `-${value}%`;
};

export const transformCartItemConfigurableOptions = item => {
    const { configurable_options: configurableOptions } = item;
    let chosenSizeLabel = '';
    let sizeValues = {};
    const sizeAttrIndex = 0;
    const sizeAttr = configurableOptions[sizeAttrIndex];

    if (sizeAttr) {
        const {
            id: sizeAttrCode,
            option_label: sizeAttrLabel,
            value_id: sizeValueCode,
            value_label: sizeValueLabel,
        } = sizeAttr;

        sizeValues = {
            attribute: {
                code: sizeAttrCode,
                label: sizeAttrLabel,
            },
            value: {
                code: sizeValueCode,
                label: sizeValueLabel,
            },
        };

        chosenSizeLabel = sizeValueLabel;
    }

    return {
        chosenSizeLabel,
        sizeValues,
    };
};

export const transformCartItem = ({ item }) => {
    const {
        id,
        skuVariant,
        product: {
            analytics,
            associations,
            badges,
            color,
            fason,
            isActive,
            isOneSize,
            model,
            seriesName,
            sexName,
            family,
            brandName,
            brandUrl,
            categories,
            sku,
            name,
            image = {},
            url_key,
            url,
            images,
            sizeType,
            variants = {},
            values,
            purpose,
            style,
            currency,
            promoSticker,
        },
        prices,
        ext_stock_count: stockCount,
        quantity,
        configurable_options: configurableOptions,
    } = item;

    const {
        final_price: finalPriceTotal,
        formatted_final_price: finalPriceFormattedTotal,
        final_item_price: finalPrice,
        item_original_price: regularPrice,
        formatted_final_item_price: finalPriceFormatted,
        formatted_item_original_price: regularPriceFormatted,
        discount_percent_by_special_price: discountPercent,
        original_price: regularPriceTotal,
        formatted_original_price: regularPriceTotalFormatted,
        omnibus_price: omnibusPrice = 0,
        formatted_omnibus_price: omnibusPriceFormatted,
        omnibus_discount_amount: omnibusDiscountAmount,
    } = prices;

    const omnibusDiscountPercent =
        !omnibusPrice || omnibusDiscountAmount <= 0
            ? null
            : omnibusDiscountAmount;

    const transformed = {
        analytics,
        associations,
        badges,
        brandName,
        color,
        fason,
        isActive,
        isOneSize,
        model,
        seriesName,
        id,
        sku,
        skuVariant,
        name,
        quantity,
        categories,
        variants,
        url_key,
        image: {
            src: image.url,
            alt: image.label,
        },
        images,
        currency,
        promoSticker,
        discount: {
            regular: {
                amount: discountPercent,
                formatted: formatDiscount(discountPercent),
            },
            omnibus: {
                amount: omnibusDiscountPercent,
                formatted: formatDiscount(omnibusDiscountPercent),
            },
        },
        price: {
            regular: {
                amount: regularPrice,
                formatted: regularPriceFormatted,
            },
            regularTotal: {
                amount: regularPriceTotal,
                formatted: regularPriceTotalFormatted,
            },
            promotional: {
                amount: finalPrice,
                formatted: finalPriceFormatted,
            },
            promotionalTotal: {
                amount: finalPriceTotal,
                formatted: finalPriceFormattedTotal,
            },
            omnibus: {
                amount: omnibusPrice,
                formatted: omnibusPrice ? omnibusPriceFormatted : '',
            },
        },
    };

    const {
        sizeValues,
        chosenSizeLabel,
    } = transformCartItemConfigurableOptions(item);

    const chosenSize = getVariantKey(variants, skuVariant);

    const itemValues = {
        size: sizeValues,
    };

    if (values && Object.keys(values).length) {
        const colorValues = values[COLOR_ATTRIBUTE_NAME];

        if (colorValues) {
            itemValues.color = colorValues;
        }
    }

    return {
        ...transformed,
        path: url,
        chosenSize,
        chosenSizeLabel,
        brand: brandName,
        brand_url: brandUrl,
        size_type: sizeType,
        sexName,
        purpose,
        style,
        family,
        isSimpleProduct: !configurableOptions,
        values: itemValues,
        stockCount,
    };
};

export default {
    transformCartItem,
};
