<template>
    <div class="checkout-cart-layout">
        <CheckoutCartHeader :current-step="1" />
        <main>
            <nuxt />
        </main>
        <Messages />
        <CheckoutFooter />
        <ChatWrapper />
        <GlobalModals />
        <ModalNewsletter
            v-if="isOpen && !isModivoClubEnabled"
            :is-open="isOpen"
            @close="close()"
        />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { MODAL_NEWSLETTER_NAME } from '@configs/modals';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import CheckoutFooter from '@organisms/CheckoutFooter/CheckoutFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import CheckoutCartHeader from '@organisms/CheckoutCartHeader/CheckoutCartHeader';

import BaseLayout from '@layouts/mixins/BaseLayout';

export default {
    name: 'CheckoutCartLayoutV2',

    components: {
        ChatWrapper,
        Messages,
        CheckoutFooter,
        GlobalModals,
        CheckoutCartHeader,
        SmartBannerWrapper,

        ModalNewsletter: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "modal-newsletter" */ '@organisms/ModalNewsletter/ModalNewsletter'
            ),
        }),
    },

    mixins: [BaseLayout],

    computed: {
        isOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_NAME);
        },

        isModivoClubEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },
    },

    methods: {
        close() {
            this.$modals.close(MODAL_NEWSLETTER_NAME);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-cart-layout {
    @apply min-h-[100vh] bg-ui-swap-gray100;
}
</style>
